.topIcon {
    width: 49px;
    height: 45px;
}

.topIconContainer {
    width: 64px;
    height: 64px;
    margin: 0 auto;
}

.title {
    color: var(--order-error-title-color) !important;
}

.message {
    margin-top: 8px;
    font-size: var(--desk-font-size-1-6);
    line-height: 130%;
    font-weight: 500;
    color: var(--order-error-details-color);

    font-family: "Inter";
    font-style: normal;

    text-align: center;
}

.depositIcons {
    text-align: center;
    margin-top: 32px;
}

.errorHighlight {
    color: var(--action-sell);
}
