.overlay {
    position: absolute;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(16, 17, 21, 0.8);
}

.container {
    display: flex;

    flex-direction: column;
    background: var(--bg);
    position: absolute;
    z-index: 10;
    top: 16px;
    bottom: 16px;
    border-radius: 4px;
    left: 16px;
    right: 16px;
    overflow-y: hidden;
}

.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    height: 48px;
    min-height: 48px;

    .closeIcon {
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 2;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;

        background-image: var(--close-icon-bg);

        &:hover {
            background-image: url("/images/close_cross_pale.svg");
        }
    }
}

.content {
    width: 100%;
    text-align: center;
    overflow-y: auto;
    flex-grow: 1;
    padding: 0 12px 85px 12px;

    max-height: calc(100% - 119px);

    &::-webkit-scrollbar-thumb {
        visibility: hidden;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            visibility: visible;
        }
    }

    h2 {
        color: #e6ecf1;
        font-size: var(--desk-font-size-2-4);
        line-height: 1.3;
        font-weight: 700;
    }
}

.footer {
    position: absolute;
    bottom: 0;
    left: 12px;
    right: 12px;
    display: flex;
    background: var(--bg);
    padding-top: 8px;
    padding-bottom: 12px;
    z-index: 5;

    .actionButton {
        color: var(--order-modal-action-btn-color);
        background: var(--order-modal-action-btn-bg);
        padding: 12px;
        border-radius: 8px;
        text-transform: none;
        font-size: var(--desk-font-size-1-4);
        line-height: 1.1;
        font-weight: 700;
        box-shadow: none;

        &:hover {
            background: var(--order-modal-action-btn-hover-bg);
            box-shadow: none;
        }

        &.touched {
            color: var(--order-modal-action-pressed-btn-color);
            background: var(--order-modal-action-pressed-btn-bg);
        }

        .disabled {
            background: red;
        }
    }

    .customFooter {
        width: 100%;
        max-height: 50px;
        display: flex;
        background-color: var(--bg) !important;
    }

    .btnContainer {
        display: flex;
        width: 100%;
        gap: 8px;

        .cancelBtn {
            background-color: var(--order-close-modal-btn-cancel-bg);
            color: var(--order-delete-confirm-modal-color);
            border: 2px solid var(--order-delete-confirm-modal-border);

            &:hover {
                background-color: var(--order-delete-confirm-modal-hover);
                color: var(--order-delete-confirm-modal-hover-color);
                border: 2px solid var(--order-delete-confirm-modal-hover-border);
            }

            &.touched {
                background-color: var(--order-close-modal-pressed-btn-cancel-bg);
                color: var(--order-close-modal-pressed-btn-cancel-color);
                border: 2px solid transparent;
            }
        }
    }
}