::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #BBC9D5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll-thumb-bg-hover);
}

.PrivateSwipeArea-root {
  height: inherit !important;
}
