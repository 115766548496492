@import "./css-global-variables";
@import "./variables.scss";
@import "./icons.css";

@import "./chart";
@import "mixins.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--scroll-thumb-bg);
        border-radius: 3px;
        height: 107px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--bg);
    }
}

p {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -o-user-select: text;
    user-select: text;
}

html {
    font-size: 10px;
    font-family: var(--main-font-family);
    font-weight: 500;
    box-sizing: border-box;
    overflow-x: hidden;
    scroll-behavior: smooth;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: var(--bg); // improves scroll looks on mobile
}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
//     html {
//         transform: rotate(-90deg);
//         transform-origin: left top;
//         width: 100vh !important;
//         height: 100vw !important;
//         overflow-x: hidden;
//         position: absolute;
//         top: 100%;
//         left: 0;
//     }

//     body {
//         width: 100vh;
//         height: 100vw;
//     }
// }

body,
html {
    width: 100%;
    height: 100%;
    overscroll-behavior-y: contain;
    overflow: hidden;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

a,
a:focus,
a:visited {
    text-decoration: none;
    outline: none;
}

img {
    max-width: 100%;
    height: auto;
}

h1,
h2,
h3,
h4 {
    font-weight: 700;
}

button:disabled {
    opacity: 0.2;
    pointer-events: none;
    user-select: none;
}

.MuiTabs-indicator {
    background-color: none !important;
    width: 0 !important;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
    display: none !important;
}

.react-datepicker {
    width: 246px;
    height: 288px;

    font-size: 1.2rem;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    margin: 4px;
}

.react-datepicker-popper {
    z-index: 1400 !important;
}

.react-datepicker__day--keyboard-selected {
    border-radius: 1.3rem !important;
    background-color: transparent !important;
    color: #343841 !important;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}

.react-datepicker {
    font-family: "Inter";
    background-color: #fff;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    position: relative;
    filter: drop-shadow(0px 5px 20px rgba(52, 56, 65, 0.5));
}

.react-datepicker--time-only {
    .react-datepicker__triangle {
        left: 0px;
    }

    .react-datepicker__time-container {
        border-left: 0;
    }

    .react-datepicker__time,
    .react-datepicker__time-box {
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 1;

    &[data-placement^="bottom"] {
        padding-top: 8px + 2px;

        .react-datepicker__triangle {
            // @extend %triangle-arrow-up;
        }
    }

    &[data-placement="bottom-end"],
    &[data-placement="top-end"] {
        .react-datepicker__triangle {
            left: auto;
            right: 50px;
        }
    }

    &[data-placement^="top"] {
        padding-bottom: 8px + 2px;

        .react-datepicker__triangle {
            //@extend %triangle-arrow-down;
        }
    }

    &[data-placement^="right"] {
        padding-left: 8px;

        .react-datepicker__triangle {
            left: auto;
            right: 42px;
        }
    }

    &[data-placement^="left"] {
        padding-right: 8px;

        .react-datepicker__triangle {
            left: 42px;
            right: auto;
        }
    }
}

.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    // border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;

    &--time {
        padding-bottom: 8px;
        padding-left: 5px;
        padding-right: 5px;

        &:not(&--only) {
            border-top-left-radius: 0;
        }
    }

    &:not(&--has-time-select) {
        border-top-right-radius: 0.3rem;
    }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    //color: #000;
    font-weight: bold;
    font-size: 1.2rem;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;

    &--previous {
        left: 2px;
    }

    &--next {
        right: 2px;

        &--with-time:not(&--with-today-button) {
            right: 85px;
        }
    }

    &--years {
        position: relative;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;

        &-previous {
            top: 4px;
        }

        &-upcoming {
            top: -4px;
        }
    }

    &:hover {
        *::before {
            border-color: darken(#ccc, 15%);
        }
    }
}

.react-datepicker__navigation-icon {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: 0;

    &::before {
        //@extend %navigation-chevron;
    }

    &--next {
        left: -2px;

        &::before {
            transform: rotate(45deg);
            left: -7px;
        }
    }

    &--previous {
        right: -2px;

        &::before {
            transform: rotate(225deg);
            right: -7px;
        }
    }
}

.react-datepicker__month-container {
    float: left;
    width: 100%;
    height: 100%;
}

.react-datepicker__year {
    margin: 0.4rem;
    text-align: center;

    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 180px;
    }

    .react-datepicker__year-text {
        display: inline-block;
        width: 4rem;
        margin: 2px;
    }
}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;

    .react-datepicker__month-text,
    .react-datepicker__quarter-text {
        display: inline-block;
        width: 4rem;
        margin: 2px;
    }
}

.picker-container .picker-column .picker-item.picker-item-selected {
    color: var(--timepicker-swiper-item-selected) !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

.picker-container .picker-column .picker-item {
    color: var(--timepicker-swiper-item-unselected) !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    /* identical to box height, or 178% */

    text-align: center;

    /* Grey/Dark Grey - 4C515E */

    //color: #4c515e !important;
}

.picker-highlight {
    &:after {
        height: 0 !important;
    }
    &:before {
        height: 0 !important;
    }
}
.react-datepicker__input-time-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 244px;

    .react-datepicker-time__caption {
        display: none;
    }

    .react-datepicker-time__input-container {
        display: inline-block;

        .react-datepicker-time__input {
            display: flex;
            margin-left: 0 !important;
            // border: 1px solid #aeaeae;

            input {
                width: auto;
            }

            input[type="time"]::-webkit-inner-spin-button,
            input[type="time"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type="time"] {
                -moz-appearance: textfield;
            }
        }

        .react-datepicker-time__delimiter {
            margin-left: 5px;
            display: inline-block;
        }
    }
}

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 85px;

    &--with-today-button {
        display: inline;
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
        position: absolute;
        right: -72px;
        top: 0;
    }

    .react-datepicker__time {
        position: relative;
        background: white;
        border-bottom-right-radius: 0.3rem;

        .react-datepicker__time-box {
            width: 85px;
            overflow-x: hidden;
            margin: 0 auto;
            text-align: center;
            border-bottom-right-radius: 0.3rem;

            ul.react-datepicker__time-list {
                list-style: none;
                margin: 0;
                height: calc(195px + (#{1.7rem} / 2));
                overflow-y: scroll;
                padding-right: 0;
                padding-left: 0;
                width: 100%;
                box-sizing: content-box;

                li.react-datepicker__time-list-item {
                    height: 30px;
                    padding: 5px 10px;
                    white-space: nowrap;

                    &:hover {
                        cursor: pointer;
                        background-color: #f0f0f0;
                    }

                    &--selected {
                        background-color: #343841;
                        color: white;
                        font-weight: bold;

                        &:hover {
                            background-color: #343841;
                        }
                    }

                    &--disabled {
                        color: #ccc;

                        &:hover {
                            cursor: default;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}
.react-datepicker__week {
    display: flex;
}
.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 2.7rem;
    height: 2.7rem;
    line-height: 2.7rem;
    text-align: center;
    margin: 4px;

    &.react-datepicker__week-number--clickable {
        cursor: pointer;

        &:hover {
            border-radius: 0.3rem;
            background-color: #f0f0f0;
        }
    }
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-names {
    margin-bottom: -8px;
}
.react-datepicker__day-name {
    display: inline-block;
    width: 2.7rem;
    height: 2.7rem;
    line-height: 2.7rem;
    text-align: center;
    color: #89909c;
    font-size: 1.1rem;
    margin: 4px;
}

.react-datepicker__day,
.react-datepicker__time-name {
    display: inline-block;
    width: 2.7rem;
    height: 2.7rem;
    line-height: 2.7rem;
    text-align: center;
    margin: 4px;
}

.react-datepicker__month,
.react-datepicker__quarter {
    &--selected,
    &--in-selecting-range,
    &--in-range {
        border-radius: 0.3rem;
        background-color: #343841;
        color: #fff;

        &:hover {
            background-color: darken(#343841, 5%);
        }
    }

    &--disabled {
        color: #ccc;
        pointer-events: none;

        &:hover {
            cursor: default;
            background-color: transparent;
        }
    }
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    cursor: pointer;

    &:hover {
        border-radius: 80px;
        background-color: #dbdbdb;
    }

    &--today {
        font-weight: bold;
    }

    &--highlighted {
        border-radius: 80px;
        background-color: #3dcc4a;
        color: #fff;

        &:hover {
            background-color: darken(#3dcc4a, 5%);
        }

        &-custom-1 {
            color: magenta;
        }

        &-custom-2 {
            color: green;
        }
    }

    &--selected,
    &--in-selecting-range,
    &--in-range {
        border-radius: 80px;
        background-color: #343841;
        color: #fff;

        &:hover {
            background-color: darken(#343841, 5%);
        }
    }

    &--keyboard-selected {
        border-radius: 80px;
        background-color: lighten(#343841, 5%);
        color: #fff;

        &:hover {
            background-color: darken(#343841, 5%);
        }
    }

    &--in-selecting-range:not(&--in-range) {
        background-color: rgba(#343841, 0.5);
    }

    &--in-range:not(&--in-selecting-range) {
        .react-datepicker__month--selecting-range & {
            background-color: #f0f0f0;
            //color: #000;
        }
    }

    &--disabled {
        cursor: default;
        color: #ccc;

        &:hover {
            background-color: transparent;
        }
    }
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {
    &.react-datepicker__month--selected,
    &.react-datepicker__month--in-range,
    &.react-datepicker__quarter--selected,
    &.react-datepicker__quarter--in-range {
        &:hover {
            background-color: #343841;
        }
    }

    &:hover {
        background-color: #f0f0f0;
    }
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
    position: relative;

    &:hover {
        cursor: pointer;

        .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view--down-arrow {
            border-top-color: darken(#ccc, 10%);
        }
    }

    &--down-arrow {
        //@extend %navigation-chevron;

        transform: rotate(135deg);
        right: -16px;
        top: 0;
    }
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;

    &:hover {
        cursor: pointer;
    }

    &--scrollable {
        height: 150px;
        overflow-y: scroll;
    }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
    }

    &:last-of-type {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }

    &:hover {
        background-color: #ccc;

        .react-datepicker__navigation--years-upcoming {
            border-bottom-color: darken(#ccc, 10%);
        }

        .react-datepicker__navigation--years-previous {
            border-top-color: darken(#ccc, 10%);
        }
    }

    &--selected {
        position: absolute;
        left: 15px;
    }
}

.react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;

    &::after {
        cursor: pointer;
        background-color: #343841;
        color: #fff;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        padding: 2px;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        content: "\00d7";
    }
}

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 3rem;
        line-height: 3rem;
    }

    @media (max-width: 400px), (max-height: 550px) {
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            width: 2rem;
            line-height: 2rem;
        }
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
        font-size: 0.8rem * 1.8;
    }
}
.react-datepicker__day--outside-month {
    color: #89909c;
}
.react-datepicker__year {
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
    height: 184px;
}

.react-datepicker__year .react-datepicker__year-text {
    margin: 0 8px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-datepicker__year-wrapper {
    max-width: 184px;
    justify-content: center;
}

@media (min-width: 1047px) and (max-width: 1053px) {
    prochart .layout-innner-container .layout-col {
        top: 34px !important;
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px transparent inset !important;
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}
/* perfect scrollbar */
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent !important;
    opacity: 1 !important;
}

.ps__thumb-y {
    background: #bbc9d5 !important;
    opacity: 0.3 !important;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #bbc9d5 !important;
    opacity: 0.3 !important;
}

html.theme-light {
    .ps__thumb-y {
        opacity: 0.8 !important;
    }

    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y {
        opacity: 0.8 !important;
    }
}
