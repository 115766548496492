#app-theme-root {
    .chart-preload-container {
        display: none;
    }

    .chart-pane-legend.chart-pane-legend-price {
        left: auto!important;
        right: 50px!important;
    }

    #trading_chart {
        .chart-pane-legend.chart-pane-legend-price {
            top: -37px!important; // desktop

            .legend-price-container {
                font-family: "Inter", sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
            }
        }
    }

    .chart-pane-legend.chart-pane-legend-price .legend-price-description.legend-description {
        display: none!important;
    }

    .CoreCont {
        /*display: none!important; !* TODO: remove *!*/
    }

    prochart {

        .layout1 button {
            height: 26px;
            width: 32px;
        }

        .layout1 .chart-pane-main {
            border-top: 1px solid #333;
        }

        .layout-innner-container .layout-left {
            margin-top: -68px;
        }

        .layout1 ul.toolbar-adv .tlb-array {
            display: none;
        }

        .layout1 ul.toolbar-adv .tlb-item-group .tlb-button-groups button {
            border: 1.5px solid transparent;
        }

        .layout1 button {
            //height: 27px!important;
        }

        .layout1 ul.toolbar-adv.loc-vertical .tlb-item {
            margin-bottom: 4px;
        }

        .tlb-space.loc-horizontal {
            display: none!important;
        }

        .toolbar-adv.loc-horizontal .tlb-item {
            margin-right: 1px;
        }

        .toolbar-adv.loc-horizontal .tlb-space {
            margin-left: -1px;
        }

        .layout1 button.legend-price-collapsebutton {
            display: none!important;
        }

        .toolbar-adv .icon-button span {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            width: 18px;
            height: 16px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        .toolbar-adv .tlb-more-item .icon-button span {
            right: auto;
            left: 6px;
        }

        .toolbar-adv .tlb-more-item .icon-button,
        .toolbar-adv .tlb-more-item .caption-button {
            border: none;
        }

        .chart-price-zoom .zoom-buttons {
            opacity: 1!important;
        }

        .zoom-buttons button {
            border-radius: 0;

            &:not(:last-of-type) {
                border-right: none!important;
            }

            &:first-child {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }

            &:last-of-type {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }

        .chart-price-zoom .zoom-buttons .icon-button {
            width: 26px;
            height: 24px;
        }

        .chart-price-zoom .zoom-buttons .icon-button span {
            height: 12px;
            width: 9px;
            top: 6px;
            left: 9px;
        }

        .icon-button span {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }

        .layout1 .layout-top ul.toolbar-adv > li span.toolbar-adv-space {
            height: 18px!important;
        }

        .layout1 ul.toolbar-adv .sub-point {
            bottom: -1px;
            right: 1px;
        }
    }
}

/**
 * Dialogs and other global elements
 */
.indicators-dialog {
    .ui-dialog-buttonpane {
        .ui-dialog-buttonset {
            button.eye-orange {
                display: none!important;
            }
        }
    }
}

.chart-ui-widget-overlay.ui-widget-overlay {
    opacity: 0.8;
    background: #000;
}

#trading_chart_edit_trade {
    .legend-price-collapsebutton {
        display: none!important;
    }
}

.chart-button.eye-orange {
    display: none!important;
}
