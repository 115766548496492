.orderInfoView {

    li {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: var(--desk-font-size-1-2);
        line-height: 16px;
        color: var(--system-feedback-mbl-default-color);
        border-radius: 5px;
        padding: 6px 12px;

        .label {
            font-weight: 700;
        }

        &:nth-child(2n) {
            background: var(--order-error-modal-info-area-bg);
        }
    }

    .rowInfoDesktop {
        color: var(--order-close-modal-color);

        &:nth-child(2n) {
            background: var(--order-error-modal-info-area-desktop-bg);
        }
    }
}
