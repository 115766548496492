.errorIconContainer {
    width: 64px;
    height: 64px;
    margin: 0 auto;
    margin-bottom: 24px;
}
.errorTopIcon {
    width: 49px;
    height: 45px;
}

.errorTitle {
    color: var(--order-error-title-color) !important;
}

.errorDetails {
    margin-top: 8px;
    font-weight: 500;
    font-size: var(--desk-font-size-1-5);
    line-height: 130%;
    text-align: center;
    color: var(--order-error-details-color);
}

.title {
    color: var(--order-error-details-color) !important;
}
