.container {
    width: 18px;
    height: 18px;
    background: transparent;
    border: 1px solid var(--checkbox-border);
    border-radius: 2px;
    margin-right: 6px;
    cursor: pointer;

    &:hover {
        border-color: var(--checkbox-border-hover);
    }

    &.checked {
        background: var(--checkbox-bg-checked);
        border-color: var(--checkbox-border-checked);

        &:before {
            content: '';
            display: block;
            position: relative;
            left: 2px;
            top: 3px;
            width: 12px;
            height: 10px;
            background-size: 100% 100%;
            background-image: var(--checkbox-icon-url)
        }
    }

    &.disabled {
        border-color: var(--checkbox-border-disabled);
        cursor: default;
        pointer-events: none;
    }
}

.checkboxDark {
    border: 1px solid #000000;

    &.checked {
        border-color: #343841;
        background-color: #343841;

        &:before {
            background-image: url('/images/checkbox-check_white.svg');
        }
    }

    &:hover {
        border-color: #000000;
    }
}

.input {
    display: none;
}

.checkboxMobileSize {
    width: 20px;
    height: 20px;
    margin: 2px;

    &.checked {
        background: var(--checkbox-bg-checked);
        border-color: var(--checkbox-border-checked);

        &:before {
            content: '';
            display: block;
            position: relative;
            left: 3px;
            top: 4px;
            background: var(--checkbox-bg-checked);
            border-color: var(--checkbox-border-checked);
            background-image: var(--checkbox-icon-url);
        }
    }
}
