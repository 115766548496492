// Dark theme (default) & globals
:root {
    --some-test-bg: #fff;
    // Font-sizes for desktop
    // (For desktop, some default values are reduced to 75% as it looks better)
    --desk-font-size-xsm: calc(10px * 1);
    --desk-font-size-xsm1: calc(11px * 1);
    --desk-font-size-xsm2: 8.4px;
    --desk-font-size-xsm3: 7.7px;
    --desk-font-size-xs: calc(12px * 1);
    --desk-font-size-xs1: calc(13px * 1);
    --desk-font-size-md: calc(14px * 1);
    --desk-font-size-md1: calc(15px * 1);
    --desk-font-size-lg: calc(16px * 1);
    --desk-font-size-lg1: calc(18px * 1);
    --desk-font-size-xl: calc(20px * 1);
    --desk-font-size-xl1: calc(22px * 1);
    --desk-font-size-xl2: calc(24px * 1);
    --desk-font-size-xxl: calc(28px * 1);
    --desk-font-size-xxl1: calc(30px * 1);
    --desk-font-size-xxl2: calc(32px * 1);
    --desk-font-size-xxl3: calc(36px * 1);

    --main-font-family: "Inter";

    --desk-font-size-05: 0.5rem;
    --desk-font-size-06: 0.6rem;
    --desk-font-size-07: 0.7rem;
    --desk-font-size-08: 0.8rem;
    --desk-font-size-09: 0.9rem;
    --desk-font-size-1: 1rem;
    --desk-font-size-1-1: 1.1rem;
    --desk-font-size-1-2: 1.2rem;
    --desk-font-size-1-3: 1.3rem;
    --desk-font-size-1-4: 1.4rem;
    --desk-font-size-1-5: 1.5rem;
    --desk-font-size-1-6: 1.6rem;
    --desk-font-size-1-7: 1.7rem;
    --desk-font-size-1-8: 1.8rem;
    --desk-font-size-1-9: 1.9rem;
    --desk-font-size-2: 2rem;
    --desk-font-size-2-1: 2.1rem;
    --desk-font-size-2-2: 2.2rem;
    --desk-font-size-2-3: 2.3rem;
    --desk-font-size-2-4: 2.4rem;
    --desk-font-size-2-5: 2.5rem;
    --desk-font-size-2-6: 2.6rem;
    --desk-font-size-2-7: 2.7rem;
    --desk-font-size-2-8: 2.8rem;
    --desk-font-size-3: 3rem;
    --desk-font-size-3-2: 3.2rem;
    --desk-font-size-3-5: 3.5rem;
    --desk-font-size-3-6: 3.6rem;
    --desk-font-size-4: 4rem;
    --desk-font-size-4-4: 4.4rem;
    --desk-font-size-4-8: 4.8rem;
    --desk-font-size-5: 5rem;
    --desk-font-size-5-4: 5.4rem;
    --desk-font-size-6: 6rem;
    --desk-font-size-7: 7rem;

    // Font-sizes for mobile
    --mbl-font-size-xsm: 10px;
    --mbl-font-size-xs: 12px;
    --mbl-font-size-md: 14px;
    --mbl-font-size-md1: 15px;
    --mbl-font-size-lg: 16px;
    --mbl-font-size-xl: 20px;
    --mbl-font-size-xl1: 22px;
    --mbl-font-size-xl2: 24px;
    --mbl-font-size-xxl: 28px;

    // Font-sizes for mobile
    --mbl-font-size-xsm: 10px;
    --mbl-font-size-xs: 12px;
    --mbl-font-size-md: 14px;
    --mbl-font-size-md1: 15px;
    --mbl-font-size-lg: 16px;
    --mbl-font-size-xl: 20px;
    --mbl-font-size-xl1: 22px;
    --mbl-font-size-xl2: 24px;
    --mbl-font-size-xxl: 28px;

    --header-mbl-height: 48px;
    --footer-height: 5.8rem;

    @media (max-width: 1366px) {
        --footer-height: 46px;
    }

    --footer-mbl-height: 75px;
    --trades-tables-height: 295px;
    --header-toolbar-height: 5.5rem;
    --asset-list-margin-info-mbl-height: 54px;
    --asset-list-toolbar-mbl-height: 40px;
    --asset-list-active-search-toolbar-mbl-height: 56px;
    --asset-categories-height: 2.6rem;
    --asset-categories-mbl-height: 24px;
    --asset-categories-container-mbl-height: 61px;

    --footer-bg: #101115;
    --footer-label-color: #89909c;
    --footer-value-color: #ffffff;
    --footer-separator-border: #343841;
    --footer-border: transparent;
    --footer-mbl-color: #4c515e;
    --footer-mbl-border: transparent;
    --footer-mbl-active-color: #ffffff;

    --footer-home-icon-mbl-active: url("/images/home_white.svg");
    --footer-update-icon-mbl-active: url("/images/update_white.svg");
    --footer-wallet-icon-mbl-active: url("/images/wallet_white.svg");
    --footer-home-icon-mbl: url("/images/home_grey.svg");
    --footer-update-icon-mbl: url("/images/update_grey.svg");
    --footer-wallet-icon-mbl: url("/images/wallet_grey.svg");

    --asset-list-row-bg: #1c1f25;
    --asset-list-row-touched-bg: #1A2534;
    --asset-list-row-highlighted-bg: #1A2534;

    --text-primary: #fff;
    --text-secondary: #89909c;
    --text-active: #e6ecf1;

    --scroll-thumb-bg-hover: #555;
    --scroll-thumb-bg: rgba(187, 201, 213, 0.3);

    --bg-lvl0: #101115;
    --bg-lvl1: #15171c;
    --bg-lvl2: #1c1f25;

    --action-buy: #2cc47e;
    --action-sell: #f25555;

    --action-buy-disabled: #1b2e2c;
    --action-sell-disabled: #31242a;

    --action-buy-pressed: #094B2C;
    --action-sell-pressed: #612222;

    --profit: #16bb6f;
    --loss: #f25555;

    // app globals
    --bg: #1c1f25;
    --layout-bg: #1d1f24;
    --color: #fff;
    --layout-separator-color: #101115;
    --loader-bg: #1c1f25;

    // header
    --light-grey: #e0e5f0;
    --black: #000000;

    --backdrop-bg: rgba(0, 0, 0, 0.8);

    --button-disabled-color: #676B76;
    --button-disabled-bg: #E6ECF1;

    --close-icon-bg: url("/images/close_cross_white.svg");

    // fallback screen
    --fallback-screen-icon: url("/images/fallback.svg");
    --fallback-screen-mobile-icon: url("/images/fallback_mobile.svg");
    --fallback-screen-tablet-icon: url("/images/fallback_tablet.svg");
    --fallback-screen-action-btn-bg: #ffffff;
    --fallback-screen-action-btn-color: #343841;
    --fallback-screen-title-color: #e6ecf1;
    --fallback-screen-subtitle-color: #a7aebb;

    // asset list - search
    --asset-list-search-bg: #101115;
    --asset-list-search-border: #101115;
    --asset-list-search-border-hover: #101115;
    --asset-list-search-icon-url: url("/images/search.svg");
    --asset-list-search-white-icon-url: url("/images/search_white.svg");
    --asset-list-search-grey-icon-url: url("/images/search_grey.svg");
    --asset-list-search-color: #89909c;
    --asset-list-search-hover-color: #e6ecf1;
    --asset-list-search-text-color: #e6ecf1;
    --asset-list-expand-icon-url: url("/images/maximize.svg");
    --asset-list-expand-icon-url-hover: url("/images/maximize-hover.svg");
    --asset-list-favorite-reset-list-icon-url: url("/images/circle_arrow.svg");
    --asset-list-empty-list-color: #e6ecf1;

    --asset-list-margin-info-mbl-bg: #343841;
    --asset-list-margin-info-mbl-color: #ffffff;

    --asset-list-search-title-color: #a7aebb;
    --asset-list-search-no-results-bg: #f2f2f2;
    --asset-list-search-no-results-color: #e5ecf2;
    --asset-list-search-no-results-icon-url: url("/images/search_not_found.svg");

    --asset-list-mbl-search-outline: #4c515e;
    --asset-list-mbl-search-border: transparent;
    --asset-list-mbl-search-active-bg: #1c1f25;

    // asset list - toolbar btn Container
    --asset-list-btn-container-bg: #343841;
    --asset-list-btn-active-bg: #15181d;
    --asset-list-chart-btn-active-bg: #ffffff;

    // asset list - categories
    --asset-list-category-bg: #101115;
    --asset-list-category-border: #101115;
    --asset-list-category-border-mobile: #15181d;
    --asset-list-category-color: #89909c;
    --asset-list-category-hover-color: #ffffff;
    --asset-list-category-active-bg: #ffffff;
    --asset-list-category-active-color: #343841;
    --asset-list-category-nav-prev-bg: linear-gradient(90deg,
            rgba(28, 31, 37, 1) 0%,
            rgba(28, 31, 37, 0.8) 50%,
            rgba(28, 31, 37, 0) 100%);
    --asset-list-category-nav-next-bg: linear-gradient(270deg,
            rgba(28, 31, 37, 1) 0%,
            rgba(28, 31, 37, 0.8) 50%,
            rgba(28, 31, 37, 0) 100%);
    --asset-list-symbol-main-info-name-color: #ffffff;
    --asset-list-table-color: #a7aebb;
    --asset-list-table-active-row-bg: #15171c;
    --asset-list-price-color: #ffffff;
    --asset-list-table-border: #343841;
    --asset-list-info-block-bg: #343841;
    --asset-list-info-block-label-value: #ffffff;
    --asset-list-table-item-border-color: #343841;
    --asset-list-table-header-color: #fff;

    --asset-list-order-top-border: #4c515e;
    --asset-list-order-item-market-closed-border: #4c515e;
    --asset-list-order-default-bg: #1a2534;
    --asset-list-order-recently-opened-bg: #17212e;
    --asset-list-order-market-closed-bg: #343841;
    --asset-list-order-default-color: #e0e5f0;
    --asset-list-order-lots-bg: #343841;
    --asset-list-order-btn-bg: #343841;
    --asset-list-order-btn-pressed-bg: #1C1F25;
    --asset-list-order-btn-shadow: 0 1px 8px rgba(28, 31, 37, 0.60413);
    --asset-list-order-graph-down-url: url("/images/graph_down.svg");
    --asset-list-order-alarm-clock-url: url("/images/alarmClock.svg");
    --asset-list-order-edit-url: url("/images/edit.svg");
    --asset-list-order-delete-url: url("/images/delete.svg");
    --asset-list-order-x-url: url("/images/x.svg");
    --asset-list-order-pressed-edit-url: url("/images/edit_grey.svg");
    --asset-list-order-pressed-delete-url: url("/images/delete_grey.svg");
    --asset-list-order-pressed-x-url: url("/images/x_grey.svg");
    --asset-list-order-market-closed-lots-bg: #1c1f25;
    --asset-list-order-market-closed-lots-color: #4c515e;
    --asset-list-order-market-closed-btn-bg: #1c1f25;
    --asset-list-order-market-closed-edit-url: url("/images/edit_grey.svg");
    --asset-list-order-market-closed-delete-url: url("/images/delete_grey.svg");
    --asset-list-order-market-closed-x-url: url("/images/x_grey.svg");

    --asset-panel-margin-info-tbl-color: #9098AD;
    --asset-panel-margin-info-tbl-separator-bg : #343841;
    --asset-panel-margin-info-tbl-border: #15181D;
    --asset-panel-back-icon: url("/images/arrow_down.svg");
    --asset-panel-action-tab-bg: #1c1f25;
    --asset-panel-action-active-tab-bg: #343841;
    --asset-panel-bg: #15181d;
    --asset-panel-text-icon-color: #ffffff;

    // chart section
    --chart-loader-bg: #101115;
    --chart-loader-line-bg: #c9d0d8;
    --chart-loader-line-fill-bg: #8a909b;

    // trades section (mobile)
    --trades-section-tabs-mbl-bg: #343841;
    --trades-section-tabs-mbl-color: #e0e5f0;
    --trades-section-tabs-mbl-active-bg: #ffffff;
    --trades-section-tabs-mbl-active-color: #000000;
    --trades-section-list-item-mbl-border: #4c515e;
    --trades-section-list-item-header-mbl-color: #ffffff;
    --trades-section-list-item-btn-mbl-border: #e0e5f0;
    --trades-section-list-item-btn-mbl-active-bg: #e0e5f0;
    --trades-section-list-item-btn-mbl-touched-bg: #4C515E;
    --trades-section-list-item-info-mbl-bg: #343841;
    --trades-section-list-item-info-mbl-color: #ffffff;
    --trades-section-list-item-info-mbl-shadow-color: #1c1f25;
    --trades-section-checkbox-mbl-border: #E0E5F0 ;
    --trades-section-notice-icon-mbl-bg: #343841;
    --trades-section-notice-title-mbl-color: #ffffff;
    --trades-section-selected-trade-actions-mbl-bg: #15181d;
    --trades-section-selected-trade-header-mbl-bg: #ffffff;
    --trades-section-selected-trade-header-mbl-color: #000000;
    --trades-section-selected-trade-btn-apply-mbl-color: #1c1f25;
    --trades-section-selected-trade-btn-apply-mbl-bg: #ffffff;
    --trades-section-selected-trade-btn-apply-touched-mbl-bg: #ACB1BC;
    --trades-section-selected-trade-btn-cancel-touched-mbl-bg: #343841;
    --trades-section-selected-trade-btn-cancel-touched-mbl-color: #9098AD;
    --trades-section-selected-trade-btn-apply-disabled-mbl-bg: #343841;
    --trades-section-selected-trade-btn-apply-disabled-mbl-color: #9098ad;
    --trades-section-selected-trade-profit-loss-mbl-bg: #1c1f25;
    --trades-section-selected-trade-input-number-mbl-bg: #343841;
    --trades-section-selected-trade-profit-loss-label-disabled-mbl-color: #4c515e;
    --trades-section-toolbar-btn-border: #9098ad;
    --trades-section-selected-trade-btn-apply-disabled-mbl-bg: #343841;
    --trades-section-selected-trade-btn-apply-disabled-mbl-color: #9098ad;
    --trades-section-selected-trade-profit-loss-disabled-color: #555965;
    --trades-section-selected-trade-profit-loss-color: #89909c;
    --trades-section-list-item-btn-mbl-touched-icon: url("/images/arrow_down_black.svg");

    --trades-info-section-color: #ffffff;

    --intro-first-step-button-bg: #ffffff;
    --intro-first-step-button-color: #343841;
    --intro-first-step-button-hover-bg: #acb1bc;
    --intro-close-icon-url: url("/images/close_cross_white.svg");
    --intro-modal-bg: #1c1f25;
    --intro-modal-color: #e6ecf1;
    --intro-fourth-step-chart-description-color: #ffffff;
    --intro-fourth-step-candle-image: url("/images/trade_frame.svg");
    --intro-final-modal-skip-button-hover-color: #acb1bc;

    --stepper-bullet-bg: #626772;
    --stepper-bullet-active-bg: #ffffff;
    --stepper-control-arrow-bg: #c8d0d9;
    --stepper-control-arrow-disabled-bg: #626772;
    --stepper-control-bg: #ffffff;
    --stepper-arrow-bg: #000000;

    --trade-calculator-mbl-bg: #1c1f25;
    --trade-calculator-mbl-color: #ffffff;
    --trade-calculator-display-mbl-bg: #343841;
    --trade-calculator-volume-mbl-color: #ffffff;
    --trade-calculator-volume-mbl-bg: #4c515e;
    --trade-calculator-btn-active-mbl-bg: #343841;
    --open-trades-table-row-bg: #15171c;
    --open-trades-table-row-color: #89909c;
    --open-trades-table-row-border: #343841;
    --open-trades-table-body-wrp-bg: #1c1f25;
    --open-trades-table-header-color: #e6ecf1;
    --open-trades-table-close-container-bg: #343841;
    --open-trades-table-body-wrp-top-border: #101115;
    // Modal
    --app-modal-bg: #1c1f25;
    --app-modal-color: #ffffff;
    --app-modal-shadow: 0px 1px 8px rgba(28, 31, 37, 0.60413);

    --app-modal-close-trade-line-color: #343841;
    --app-modal-close-trade-btn-transparent-color: #ffffff;
    --app-modal-close-trade-btn-transparent-border-color: #4c515e;
    --app-modal-close-trade-btn-transparent-pressed-bg: #343841;
    --app-modal-close-trade-btn-transparent-pressed-color: #9098AD ;
    --app-modal-close-trade-volume-bg: #343841;
    --app-modal-close-trade-btn-bg: #1c1f25;
    --app-modal-close-trade-btn-disabled-bg: #343841;
    --app-modal-close-trade-btn-disabled-color: #9098AD;

    --app-modal-bank-icons-visa-icon-url: url("/images/visa.png");
    --app-modal-bank-icons-mastercard-icon-url: url("/images/mastercard.png");
    --app-modal-bank-icons-bank-icon-url: url("/images/bank.png");

    --app-input-number-mbl-color: #ffffff;
    --app-input-number-mbl-bg: #1c1f25;
    --app-input-number-mbl-disabled-color: #9098ad;
    --app-input-number-mbl-disabled-bg: #343841;

    --app-toggle-mbl-bg: #343841;

    // trading order
    --trading-order-tabs-bg: #101115;
    --trading-order-tabs-active-bg: #1c1f25;
    --trading-order-tabs-color: #89909c;
    --trading-order-tabs-active-color: #e6ecf1;
    --trading-order-tabs-hover-color: #e6ecf1;
    --trading-order-info-list-row-color: #89909c;
    //--trading-order-

    --toggle-bg: #343841;
    --toggle-bg-disabled: #101115;
    --toggle-before-bg: #626772;
    --toggle-on-bg: #89909c;
    --toggle-on-before-bg: #ffffff;
    --toggle-close-order-bg: #007BFF;

    --theme-switcher-toggle-bg: #4B4F56;

    --checkbox-border: #89909c;
    --checkbox-border-hover: #e6ecf1;
    --checkbox-border-checked: #ffffff;
    --checkbox-bg-checked: #ffffff;
    --checkbox-border-disabled: #555965;
    --checkbox-icon-url: url("/images/checkbox-check.svg");

    --number-input-border: #89909c;
    --number-input-color: #e6ecf1;
    --number-input-color-bg: #e6ecf1;
    --number-input-disabled-border: #555965;
    --number-input-disabled-color: #555965;
    --number-input-focus-bg: #444854;
    --number-input-disabled-bg: #a7aebb;
    --number-input-active-bg: #343841;

    --text-input-bg-light: #e6ecf1;
    --text-input-color-light: #343841;
    --text-input-bg-dark: #555965;
    --text-input-color-dark: #e6ecf1;

    --trade-order-section-overlayed-bg: #1c1f25;

    --trades-table-btn-color: #e6ecf1;
    --trades-table-btn-border: #89909c;
    --trades-table-btn-hover-bg: #ffffff;
    --trades-table-btn-hover-border: #ffffff;
    --trades-table-btn-hover-color: #343841;

    --trades-history-table-color: #898996;
    --trades-history-table-row-border-bg: #89909c;
    --trades-history-header-table-color: #ffffff;
    --trades-history-table-bg: #1c1f25;
    --trades-history-table-body-row-hover-bg: #15171c;
    --trades-history-table-header-row-border-color: #e6ecf1;

    --trades-history-pagination-color: #E6ECF1;
    --trades-history-pagination-hover-border-color: #89909C;
    --trades-history-pagination-active-color: #343841;
    --trades-history-pagination-active-bg: #E6ECF1;
    --trades-history-pagination-dots-color: #E6ECF1;
    --trades-history-pagination-prev-icon-url: url("/images/pagination-prev-dark.svg");
    --trades-history-pagination-prev-disabled-icon-url: url("/images/pagination-prev-disabled-dark.svg");
    --trades-history-pagination-next-icon-url: url("/images/pagination-next-dark.svg");
    --trades-history-pagination-next-disabled-icon-url: url("/images/pagination-next-disabled-dark.svg");

    --trades-history-table-filter-period-list-item-bg: #101115;
    --trades-history-table-filter-period-list-item-bg-light: #000000;
    --trades-history-table-filter-period-list-item-bg-active: #ffffff;
    --trades-history-table-filter-period-list-item-bg-hover: #101115;

    --trades-history-table-filter-period-list-item-color: #89909c;
    --trades-history-table-filter-period-list-item-color-active: #343841;
    --trades-history-table-filter-period-list-item-color-hover: #ffffff;
    --trades-history-table-filter-date-picker-color-hover: #e6ecf1;
    --trades-history-table-filter-date-picker-color: #89909c;

    --trades-history-table-datepicker-icon-url: url("/images/datepicker_icon_grey2.svg");
    --trades-history-table-datepicker-icon-url-hover: url("/images/datepicker_icon_white.svg");

    --trades-history-download-pdf-btn-color: #ffffff;
    --trades-history-download-pdf-btn-hover-color: #ffffff;
    --trades-history-download-pdf-btn-border: #acb1bc;
    --trades-history-download-pdf-btn-bg: #89909c;
    --trades-history-download-pdf-btn-hover-bg: #444854;
    --trades-history-download-pdf-btn-icon: url("/images/download-pdf.svg");
    --trades-history-download-pdf-btn-hover-icon: url("/images/download-pdf.svg");
    --trades-history-mbl-download-pdf-btn-icon: url("/images/download.svg");

    --date-input-border: #89909c;
    --date-input-color: #E6ECF1;
    --date-input-url: url("/images/datepicker_calendar.svg");
    --date-input-hovew-url: url("/images/datepicker_calendar_white.svg");
    --date-input-hover-border: #a7aebb;
    --date-input-hover-color: #e6ecf1;
    --date-input-hover-bg: #4e525c;
    --date-input-hover-bg-light: #e6ecf1;
    --date-input-hover-color-light: #343841;

    --order-error-title-color: #e6ecf1;
    --order-error-details-color: #a7aebb;
    --order-error-modal-info-area-bg: #343841;
    --order-error-modal-info-area-desktop-bg: #15171C;

    --order-close-modal-btn-confirm-bg: #ffffff;
    --order-close-modal-btn-confirm-bg-hover: #acb1bc;
    --order-close-modal-btn-confirm-color: #343841;
    --order-close-modal-btn-confirm-border: none;
    --order-close-modal-btn-confirm-bg-hover: rgba(172, 177, 188, 0.30);

    --order-close-modal-btn-cancel-bg: #1C1F25;
    --order-close-modal-btn-cancel-bg-hover: rgba(172, 177, 188, 0.3);
    --order-close-modal-btn-cancel-color: #ffffff;
    --order-close-modal-btn-cancel-border: 2px solid #89909c;
    --order-close-modal-btn-cancel-border-hover: 2px solid #89909c;
    --order-close-modal-partial-checkbox-border: #89909c;
    --order-close-modal-partial-close-title-color: #e6ecf1;
    --order-close-modal-color: #89909c;
    --order-close-modal-pressed-btn-cancel-bg: #343841;
    --order-close-modal-pressed-btn-cancel-color: #9098AD;

    --order-delete-confirm-modal-color: #ffffff;
    --order-delete-confirm-modal-border: #89909C;
    --order-delete-confirm-modal-hover: #494b52;
    --order-delete-confirm-modal-hover-color: #ffffff;
    --order-delete-confirm-modal-hover-border: #89909C;

    --order-modal-action-btn-color: #101115;
    --order-modal-action-btn-bg: #fff;
    --order-modal-action-btn-hover-bg: #acb1bc;
    --order-modal-action-pressed-btn-bg: #ACB1BC;
    --order-modal-action-pressed-btn-color: #000000;

    // trading order -- form
    --trading-order-header-close-color: #ffffff;
    --trading-order-top-info-asset-name-color: #e6ecf1;
    --trading-order-buy-sell-hover-color: #ffffff;
    --trading-order-submit-bg-light: #101115;
    --trading-order-submit-color-light: #ffffff;
    --trading-order-buy-sell-disabled: #4c515e;

    --trading-order-submit-bg_dark: #ffffff;
    --trading-order-submit-color_dark: #101115;

    --trading-order-submit-hover-bg_dark: #acb1bc;
    --trading-order-submit-disabled-bg_dark: #454853;
    --trading-order-submit-disabled-color_dark: #676b76;

    --trading-order-submit-hover-bg_light: #464a53;
    --trading-order-submit-disabled-bg_light: #e4ecf1;
    --trading-order-submit-disabled-color_light: #abb0bd;

    --trading-order-modal-input-hover: #555965;
    --trading-order-modal-backdrop-bg: rgba(8, 8, 10, 0.9);
    --trading-order-submit-disabled-bg: #454853;
    --trading-order-submit-disabled-color: #676b76;

    // chart - nav
    --chart-nav-btn-bg: #101115;
    --chart-nav-btn-color: #89909c;
    --chart-nav-btn-hover-color: #e6ecf1;
    --chart-nav-btn-active-bg: #ffffff;
    --chart-nav-btn-active-color: #101115;
    // chart - nav - open trade
    --chart-nav-open-trade-btn-bg: #303540;
    --chart-nav-open-trade-btn-border: 1px solid transparent;
    --chart-nav-open-trade-btn-color: #fff;
    --chart-nav-open-trade-btn-hover-bg: #fff;
    --chart-nav-open-trade-btn-hover-color: #000;
    // chart - time
    --chart-time-color: #89909c;

    // Information section
    --info-text-color: #89909c;

    // Info List
    --infolist-heading-color: #a7aebb;
    --infolist-striped-odd-bg: #15171c;
    --infolist-striped-odd-mbl-bg: #343841;
    --infolist-glossary-bg: #15171c;
    --infolist-glossary-mbl-bg: #1c1f25;
    --infolist-glossary-label-color: #ffffff;
    --lot-size-input-empty-value-color: #343841;

    // Set lot size mobile
    --set-lot-size-mbl-default-bg: #1c1f25;
    --set-lot-size-mbl-default-color: #ffffff;
    --set-lot-size-mbl-menu-close-icon-bg: #4c515e;
    --set-lot-size-mbl-menu-close-icon-url: url("/images/x.svg");
    --set-lot-size-mbl-order-info-row-bg: #343841;

    // date range picker mobile
    --date-range-mbl-default-color: #ffffff;
    --date-range-mbl-disabled-color: #9098AD;
    --date-range-mbl-default-bg: #1c1f25;
    --date-range-mbl-view-type-border: #4c515e;
    --date-range-mbl-view-date-bg: #343841;
    --date-range-mbl-view-date-border: #343841;
    --date-range-mbl-calendar-icon-url: url("/images/smallCalendar.svg");
    --date-range-mbl-menu-close-icon-bg: #4c515e;
    --date-range-mbl-menu-close-icon-url: url("/images/x.svg");
    --date-range-mbl-menu-type-item-border: #343841;
    --date-range-mbl-calendar-header-border: #343841;
    --date-range-mbl-calendar-day-active-bg: #ffffff;
    --date-range-mbl-calendar-day-active-color: #000000;
    --date-range-mbl-calendar-day-intermediate-bg: #343841;
    --date-range-mbl-calendar-day-intermediate-color: #ffffff;
    --date-range-mbl-calendar-arrow-icon-url: url("/images/arrowRight_grey.svg");
    --date-range-mbl-calendar-arrow-active-icon-url: url("/images/arrowRight_white.svg");
    --date-range-mbl-calendar-arrow-up-icon-url: url("/images/arrow_up_white.svg");
    --date-range-mbl-calendar-arrow-up-icon-bg: #4C515E;
    --date-mbl-calendar-arrow-down-btn-url: url("/images/arrow_down_btn.svg");

    // System feedback mobile
    --system-feedback-mbl-default-color: #ffffff;
    --system-feedback-mbl-default-bg: #1c1f25;
    --system-feedback-mbl-order-info-row-bg: #343841;
    --system-feedback-mbl-btn-color: #000000;
    --system-feedback-mbl-btn-bg: #ffffff;
    --system-feedback-mbl-pressed-btn-bg: #ACB1BC;
    --system-feedback-mbl-pressed-btn-color: #000000;
    --system-feedback-mbl-description-color: #e0e5f0;
    --system-feedback-mbl-market-closed-url: url("/images/marketClosed.svg");
    --system-feedback-mbl-funds-url: url("/images/funds.svg");
    --system-feedback-mbl-title-bg: #ffffff;

    // Candlesticks tab
    --candlesticks-mbl-bg: #1c1f25;
    --candlesticks-mbl-active-bg: #f4f6fb;
    --candlesticks-mbl-color: #ffffff;
    --candlesticks-mbl-active-color: #000000;
    --candlesticks-mbl-border: #4c515e;
    --candlesticks-mbl-line-icon-url: url("/images/chartType/Line_white.svg");
    --candlesticks-mbl-line-icon-active-url: url("/images/chartType/Line.svg");
    --candlesticks-mbl-area-icon-url: url("/images/chartType/Area_white.svg");
    --candlesticks-mbl-area-icon-active-url: url("/images/chartType/Area.svg");
    --candlesticks-mbl-candlesticks-icon-url: url("/images/chartType/Candlesticks_white.svg");
    --candlesticks-mbl-candlesticks-icon-active-url: url("/images/chartType/Candlesticks.svg");
    --candlesticks-mbl-hlc-icon-url: url("/images/chartType/Bars_white.svg");
    --candlesticks-mbl-hlc-icon-active-url: url("/images/chartType/Bars.svg");
    --candlesticks-mbl-dots-icon-url: url("/images/chartType/Dots_white.svg");
    --candlesticks-mbl-dots-icon-active-url: url("/images/chartType/Dots.svg");
    --candlesticks-mbl-heikinashi-icon-url: url("/images/chartType/Heikinashi_white.svg");
    --candlesticks-mbl-heikinashi-icon-active-url: url("/images/chartType/Heikinashi.svg");
    --candlesticks-mbl-hollow-candlesticks-icon-url: url("/images/chartType/HollowCandlesticks_white.svg");
    --candlesticks-mbl-hollow-candlesticks-icon-active-url: url("/images/chartType/HollowCandlesticks.svg");
    --candlesticks-mbl-ohlc-icon-url: url("/images/chartType/OHLC_white.svg");
    --candlesticks-mbl-ohlc-icon-active-url: url("/images/chartType/OHLC.svg");
    --candlesticks-mbl-histogram-icon-url: url("/images/chartType/Histogram_white.svg");
    --candlesticks-mbl-histogram-icon-active-url: url("/images/chartType/Histogram.svg");
    --candlesticks-mbl-columns-icon-url: url("/images/chartType/Columns_white.svg");
    --candlesticks-mbl-columns-icon-active-url: url("/images/chartType/Columns.svg");
    --candlesticks-mbl-line-and-dots-icon-url: url("/images/chartType/LineAndDots_white.svg");
    --candlesticks-mbl-line-and-dots-icon-active-url: url("/images/chartType/LineAndDots.svg");

    // sentiments tab
    --sentiments-mbl-bg: #15181d;
    --sentiments-mbl-color: #ffffff;
    --sentiments-mbl-footer-text-color: #e0e5f0;

    // time frames tab
    --time-frame-mbl-btn-selected-bg: #F4F6FB;
    --time-frame-mbl-btn-border: #4C515E;
    --time-frame-mbl-btn-selected-color: #000000;

    // toast-alert
    --toast-alert-bg: #f4f6fb;
    --toast-alert-color: #000000;
    --toast-alert-icon-favorite-added-url: url("/images/FavoriteAdded.svg");
    --toast-alert-icon-favorite-removed-url: url("/images/FavoriteRemoved.svg");

    --toast-alert-top-layer-bg: #f4f6fb;
    --toast-alert-center-layer-bg: #dadce0;
    --toast-alert-bottom-layer-bg: #c1c3c7;

    // wallet mobile

    --wallet-text-color: #ffffff;
    --wallet-text-black-color: #000000;
    --wallet-text-grey-color: #9098ad;
    --wallet-text-lightgrey-color: #e0e5f0;
    --wallet-text-dark_grey-color: #4c515e;
    --wallet-text-bg: #ffffff;
    --wallet-border: #e0e5f0;
    --wallet-border-light: #f4f6fb;
    --wallet-border-touched: #007BFF;
    --wallet-close-icon-bg: #4c515e;
    --wallet-close-icon-bg-light: #e0e5f0;
    --wallet-action-bg: #343841;
    --wallet-action-bg-light: #f4f6fb;
    --wallet-deposit-icon: url("/images/deposit.svg");
    --wallet-deposit-icon-tablet: url("/images/deposit-tablet.svg");
    --wallet-withdrawal-icon: url("/images/withdrawal.svg");
    --wallet-withdrawal-icon-tablet: url("/images/withdrawal-tablet.svg");
    --wallet-info-icon: url("/images/info.svg");
    --wallet-account-icon: url("/images/accountIcon_white.svg");

    --wallet-action-panel-por-icon-bg: url("/images/por.svg");
    --wallet-action-panel-por-tablet-icon-bg: url("/images/por_tablet.svg");
    --wallet-action-panel-poi-icon-bg: url("/images/poi.svg");
    --wallet-action-panel-poi-tablet-icon-bg: url("/images/poi_tablet.svg");
    --wallet-action-panel-pod-icon-bg: url("/images/pod.svg");
    --wallet-action-panel-pod-tablet-icon-bg: url("/images/pod_tablet.svg");

    --wallet-footer-panel-request-icon-bg: url("/images/request.svg");
    --wallet-action-panel-request-icon-bg-light: url("/images/request_light.svg");
    --wallet-footer-panel-businessDay-icon-bg: url("/images/businessDay.svg");
    --wallet-action-panel-businessDay-icon-bg-light: url("/images/businessDay_light.svg");
    --wallet-action-panel-arrowRight-icon-bg: url("/images/arrowRight_white.svg");
    --wallet-action-panel-arrowRight-icon-bg-light: url("/images/arrowRight_black.svg");
    --wallet-close-btn-icon-light: url("/images/xBlack.svg");
    --wallet-close-btn-icon: url("/images/x.svg");

    --clock-icon-url: url("/images/clock.svg");
    --clock-icon-hover-url: url("/images/clock_white.svg");
    --datepicker-icon-url: url("/images/datepicker_icon_white.svg");
    --datepicker-icon-hover-url: url("/images/datepicker_icon_white2.svg");
    --datepicker-arrow-icon-url: url("/images/datepicker_arrow.svg");
    --wallet-text-color: #ffffff;
    --wallet-text-bg: #ffffff;
    --wallet-border: #e0e5f0;
    --wallet-border-light: #f4f6fb;
    --wallet-text-grey-color: #9098ad;
    --wallet-text-black-color: #000000;

    --account-info-trade-panel-item-bg: #1c1f25;
    --account-info-trade-panel-item-img-url: url("/images/accountIcon_white.svg");

    --account-info-trade-panel-item-dots-bg: #1c1f25;
    --account-info-trade-close-icon-bg: #1c1f25;
    --account-info-trade-panel-item-dots-img-url: url("/images/threeDots_white.svg");

    --calendar-drawer-date-container-color: #ffffff;
    --calendar-drawer-month-switcher-bg: #4C515E;
    --calendar-drawer-overlay-bg: #1d1f24;
    --calendar-drawer-inner-wrp-bg: #1c1f25;
    --calendar-drawer-inner-wrp-divider: #343841;
    --calendar-drawer-switcher-right-bg: url("/images/arrow_left_gray.svg");
    --calendar-drawer-switcher-right-bg-active: url("/images/arrow_right_white.svg");

    --calendar-drawer-switcher-left-bg: url("/images/arrow_left_gray.svg");
    --calendar-drawer-switcher-left-bg-active: url("/images/arrow_right_white.svg");

    --timepicker-swiper-item-selected: #ffffff;
    --timepicker-swiper-item-unselected: #4C515E;

    --calendar-container-bg: #1c1f25;
    --calendar-container-btn-color: #9098ad;
    --calendar-container-tile-active-color: #000;
    --calendar-container-tile-active-bg: #fff;
    --calendar-container-day-color: #ffffff;

    --calendar-container-tile-now-color: #fff;
    --calendar-container-tile-now-bg: #343841;

    --calendar-btn-cancel-bg: #1c1f25;
    --calendar-btn-cancel-color: #ffffff;
    --calendar-btn-set-color: #000;
    --calendar-btn-set-bg: #ffffff;
    --calendar-btn-set-disabled-bg: #343841;
    --calendar-btn-set-disabled-color: #9098AD;

    --calendar-btn-cancel-touched-bg: #343841;
    --calendar-btn-cancel-touched-color: #9098AD;
    --calendar-btn-set-touched-bg: #ACB1BC;
    --calendar-btn-set-touched-color: #000000;

    --calendar-btn-cancel-border: 2px solid #4c515e;

    --pending-orders-drawerWrp-bg: #15181d;
    --pending-orders-trade-panel-item-bg: #1c1f25;
    --pending-orders-svg-icon-url: url("/images/alarmIcon_white.svg");

    --pending-orders-trade-panel-item-dots-bg-img-url: url("/images/calendarIcon_white.svg");
    --pending-orders-trade-panel-item-dots-active-bg-img-url: url("/images/calendarIcon_blue.svg");
    --pending-orders-trade-panel-item-dots-bg: #1c1f25;

    --pending-orders-pricesetter-border: 2px solid #4c515e;
    --pending-orders-setter-bg: transparent; //#d8d8d8;

    --pending-orders-minus-bg-img-url: url("/images/minus_black.svg");
    --pending-orders-plus-bg-img-url: url("/images/plus_black.svg");

    --pending-orders-trade-panel-item-expanded-bg: #343841;
    --pending-orders-trade-panel-item-expanded-box-shadow: 0px 1px 8px rgba(28, 31, 37, 0.60413);

    --trading-assets-section-mbl-border: 1px solid #343841;
    --asset-list-category-hover-bg: #101115;

    // date picker
    --date-picker-time-bg: #ffffff;
    --date-picker-time-container-border: #343841;
    --date-picker-time-color: #000000;
    --date-picker-time-set-action-btn-color: #ffffff;
    --date-picker-time-set-action-btn-bg: #000000;
    --date-picker-time-cancel-action-btn-border: #343841;
    --date-picker-time-cancel-action-btn-color: #343841;
    --date-picker-time-set-action-btn-hover-bg: #343841;
    --date-picker-time-set-action-btn-hover-color: #ffffff;
    --date-picker-time-cancel-action-btn-hover-bg: #343841;
    --date-picker-time-cancel-action-btn-hover-color: #ffffff;

    --profit-loss-border-color-mbl: #15181d;
    --profit-loss-separator-color-mbl: #343841;
    --profit-loss-directionUp-icon: url("/images/direction_up.svg");
    --profit-loss-directionDown-icon: url("/images/direction_down.svg");
    --profit-loss-directionUp-selected-icon: url("/images/direction_up_selected.svg");
    --profit-loss-directionDown-selected-icon: url("/images/direction_down_selected.svg");
}

//--//--//--//--//--//
///---///---/// SEPARATOR
//--//--//--//--//--//

// Light theme
html.theme-light {
    // app globals
    --bg: #fff;
    --color: #101115;
    --layout-separator-color: #c8d0d9;
    --loader-bg: #fff;
    --layout-bg: #fff;

    --action-buy-disabled: #a2e4c5;
    --action-sell-disabled: #fabbbb;

    // asset list - search
    --asset-list-search-bg: #ffffff;
    --asset-list-search-border: #a7aebb;
    --asset-list-search-border-hover: #000;
    --asset-list-search-icon-url: url("/images/search_grey.svg");
    --asset-list-search-color: #9098ad;
    --asset-list-empty-list-color: #343841;
    --asset-list-search-title-color: #343841;
    --asset-list-search-no-results-bg: #343841;
    --asset-list-search-no-results-color: #000000;
    --asset-list-search-no-results-icon-url: url("/images/search_not_found_black.svg");

    --asset-list-margin-info-mbl-bg: #e0e5f0;
    --asset-list-margin-info-mbl-color: #000000;

    --asset-list-search-hover-color: #101115;
    --asset-list-search-text-color: #101115;
    --asset-list-expand-icon-url: url("/images/maximize.svg");
    --asset-list-expand-icon-url-hover: url("/images/maximize-grey.svg");
    --asset-list-favorite-reset-list-icon-url: url("/images/circle_arrow_light.svg");

    --asset-list-mbl-search-outline: #f4f6fb;
    --asset-list-mbl-search-border: #e0e5f0;
    --asset-list-mbl-search-active-bg: #ffffff;

    // asset list - toolbar btn Container
    --asset-list-btn-container-bg: #f4f6fb;
    --asset-list-btn-active-bg: #ffffff;
    --asset-list-chart-btn-active-bg: #4c515e;

    // asset list - categories
    --asset-list-category-bg: #ffffff;
    --asset-list-category-border: #a7aebb;
    --asset-list-category-border-mobile: #e0e5f0;
    --asset-list-category-color: #343841;
    --asset-list-category-hover-bg: #343841;
    --asset-list-category-hover-color: #ffffff;
    --asset-list-category-hover-color-black: #000000;

    --asset-list-category-active-bg: #101115;
    --asset-list-category-active-color: #fff;
    --asset-list-category-nav-prev-bg: linear-gradient(90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0) 100%);
    --asset-list-category-nav-next-bg: linear-gradient(270deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0) 100%);
    --asset-list-symbol-main-info-name-color: #000000;
    --asset-list-table-border: #e0e5f0;
    --asset-list-table-color: #343841;
    --asset-list-table-active-row-bg: #e6ecf1;
    --asset-list-info-block-bg: #f4f6fb;
    --asset-list-info-block-label-value: #000000;

    --asset-list-table-header-color: #000000;
    --asset-list-table-item-border-color: #c8d0d9;

    --footer-bg: #ffffff;
    --footer-label-color: #89909C;
    --footer-value-color: #343841;
    --footer-separator-border: #DBDBDB;
    --footer-border: #C8D0D9;
    --footer-mbl-color: #9098AD;
    --footer-mbl-border: #E0E5F0;
    --footer-mbl-active-color: #000000;

    --footer-home-icon-mbl-active: url("/images/home_black.svg");
    --footer-update-icon-mbl-active: url("/images/update_black.svg");
    --footer-wallet-icon-mbl-active: url("/images/wallet_black.svg");
    --footer-home-icon-mbl: url("/images/home_lightgrey.svg");
    --footer-update-icon-mbl: url("/images/update_lightgrey.svg");
    --footer-wallet-icon-mbl: url("/images/wallet_lightgrey.svg");

    --asset-list-row-bg: #ffffff;
    --asset-list-row-touched-bg: #EBF4FF;
    --asset-list-row-highlighted-bg: #F4F6FB;

    --text-primary: #000;

    --scroll-thumb-bg-hover: #BBC9D5;
    --scroll-thumb-bg: #BBC9D5;

    --asset-list-order-top-border: #e0e5f0;
    --asset-list-order-item-market-closed-border: #e0e5f0;
    --asset-list-order-default-bg: #f4f6fb;
    --asset-list-order-recently-opened-bg: #ebf4ff;
    --asset-list-order-market-closed-bg: #e0e5f0;
    --asset-list-order-default-color: #4c515e;
    --asset-list-order-lots-bg: #ffffff;
    --asset-list-order-btn-bg: #ffffff;
    --asset-list-order-btn-pressed-bg: #9098AD;
    --asset-list-order-btn-shadow: 0px 1px 8px rgba(149, 157, 177, 0.283572);
    --asset-list-order-graph-down-url: url("/images/graph_down_light_theme.svg");
    --asset-list-order-alarm-clock-url: url("/images/alarmClock_grey2.svg");
    --asset-list-order-edit-url: url("/images/edit_black.svg");
    --asset-list-order-delete-url: url("/images/delete_black.svg");
    --asset-list-order-x-url: url("/images/xBlack.svg");
    --asset-list-order-pressed-edit-url: url("/images/edit.svg");
    --asset-list-order-pressed-delete-url: url("/images/delete.svg");
    --asset-list-order-pressed-x-url: url("/images/x.svg");
    --asset-list-order-market-closed-lots-bg: #9098ad;
    --asset-list-order-market-closed-lots-color: #ffffff;
    --asset-list-order-market-closed-btn-bg: #9098ad;
    --asset-list-order-market-closed-edit-url: url("/images/edit.svg");
    --asset-list-order-market-closed-delete-url: url("/images/delete.svg");
    --asset-list-order-market-closed-x-url: url("/images/x.svg");

     --asset-panel-margin-info-tbl-color: #9098AD;
     --asset-panel-margin-info-tbl-separator-bg: #E0E5F0;
     --asset-panel-margin-info-tbl-border: #F4F6FB;
     --asset-panel-back-icon: url("/images/arrow_down_black.svg");
     --asset-panel-action-tab-bg: #e0e5f0;
     --asset-panel-action-active-tab-bg: #ffffff;
     --asset-panel-bg: #f4f6fb;
     --asset-panel-text-icon-color: #343841;

    // chart section
    --chart-loader-bg: #e6ecf1;
    --chart-loader-line-bg: #e5e5e5;
    --chart-loader-line-fill-bg: #8a909b;

    // trades section (mobile)
    --trades-section-tabs-mbl-bg: #f4f6fb;
    --trades-section-tabs-mbl-color: #4c515e;
    --trades-section-tabs-mbl-active-bg: #000000;
    --trades-section-tabs-mbl-active-color: #ffffff;
    --trades-section-list-item-mbl-border: #e0e5f0;
    --trades-section-list-item-header-mbl-color: #000000;
    --trades-section-list-item-btn-mbl-border: #e0e5f0;
    --trades-section-list-item-btn-mbl-active-bg: #4c515e;
    --trades-section-list-item-info-mbl-bg: #f4f6fb;
    --trades-section-list-item-info-mbl-color: #000000;
    --trades-section-list-item-info-mbl-shadow-color: #959db148;
    --trades-section-notice-icon-mbl-bg: #f4f6fb;
    --trades-section-checkbox-mbl-border: #000000;
    --trades-section-notice-title-mbl-color: #000000;
    --trades-section-selected-trade-actions-mbl-bg: #f4f6fb;
    --trades-section-selected-trade-header-mbl-bg: #000000;
    --trades-section-selected-trade-header-mbl-color: #ffffff;
    --trades-section-selected-trade-btn-apply-mbl-color: #ffffff;
    --trades-section-selected-trade-btn-apply-mbl-bg: #000000;
    --trades-section-selected-trade-btn-apply-touched-mbl-bg: #4C515E;
    --trades-section-selected-trade-btn-cancel-touched-mbl-bg: #4C515E;
    --trades-section-selected-trade-btn-cancel-touched-mbl-color: #ffffff;
    --trades-section-selected-trade-btn-apply-disabled-mbl-bg: #e0e5f0;
    --trades-section-selected-trade-btn-apply-disabled-mbl-color: #9098ad;
    --trades-section-selected-trade-profit-loss-mbl-bg: #ffffff;
    --trades-section-selected-trade-input-number-mbl-bg: #e0e5f0;
    --trades-section-selected-trade-profit-loss-label-disabled-mbl-color: #9098ad;
    --trades-section-toolbar-btn-border: #e0e5f0;
    --trades-section-selected-trade-btn-apply-disabled-mbl-bg: #e0e5f0;
    --trades-section-selected-trade-btn-apply-disabled-mbl-color: #9098ad;
    --trades-section-selected-trade-profit-loss-disabled-color: #a7aebb;
    --trades-section-selected-trade-profit-loss-color: #343841;
    --trades-section-list-item-btn-mbl-touched-icon: url("/images/arrow_down.svg");

    --trades-info-section-color: #343841;

    --intro-first-step-button-bg: #000000;
    --intro-first-step-button-color: #ffffff;
    --intro-first-step-button-hover-bg: #464a53;
    --intro-close-icon-url: url("/images/close_cross_black.svg");
    --intro-modal-bg: #fff;
    --intro-modal-color: #343841;
    --intro-fourth-step-chart-description-color: #343841;
    --intro-fourth-step-candle-image: url("/images/trade_light_image.svg");
    --intro-final-modal-skip-button-hover-color: #89909C;

    --stepper-bullet-bg: #dedede;
    --stepper-bullet-active-bg: #000;
    --stepper-control-arrow-bg: #c8d0d9;
    --stepper-control-arrow-disabled-bg: #c8d0d9;
    --stepper-control-bg: #1C1F25;
    --stepper-arrow-bg: #ffffff;

    --trade-calculator-mbl-bg: #ffffff;
    --trade-calculator-mbl-color: #000000;
    --trade-calculator-display-mbl-bg: #f4f6fb;
    --trade-calculator-volume-mbl-color: #000000;
    --trade-calculator-volume-mbl-bg: #e0e5f0;
    --trade-calculator-btn-active-mbl-bg: #f3f1f1;
    --trade-order-section-overlayed-bg: #ffffff;

    --open-trades-table-row-bg: #e6ecf1;
    --open-trades-table-row-color: #343841;
    --open-trades-table-row-border: #e1e4ed;
    --open-trades-table-body-wrp-bg: #ffffff;
    --open-trades-table-header-color: #343841;
    --open-trades-table-close-container-bg: #33363b;
    --open-trades-table-body-wrp-top-border: #c8d0d9;

    // Modal
    --app-modal-bg: #ffffff;
    --app-modal-color: #000000;
    --app-modal-shadow: 0px 1px 8px rgba(149, 157, 177, 0.283572);

    --app-modal-close-trade-line-color: #e0e5f0;
    --app-modal-close-trade-btn-transparent-color: #000000;
    --app-modal-close-trade-btn-transparent-border-color: #000000;
    --app-modal-close-trade-btn-transparent-pressed-bg: #4C515E;
    --app-modal-close-trade-btn-transparent-pressed-color: #ffffff;
    --app-modal-close-trade-volume-bg: #f4f6fb;
    --app-modal-close-trade-btn-bg: #ffffff;
    --app-modal-close-trade-btn-disabled-bg: #E0E5F0;
    --app-modal-close-trade-btn-disabled-color: #9098AD;

    --app-modal-bank-icons-visa-icon-url: url("/images/visa-light.png");
    --app-modal-bank-icons-mastercard-icon-url: url("/images/mastercard-light.png");
    --app-modal-bank-icons-bank-icon-url: url("/images/bank-light.png");

    --app-input-number-mbl-color: #000000;
    --app-input-number-mbl-bg: #e0e5f0;
    --app-input-number-mbl-disabled-color: #9098ad;
    --app-input-number-mbl-disabled-bg: #e0e5f0;

    --app-toggle-mbl-bg: #e0e5f0;

    // trading order
    --trading-order-tabs-bg: #e5ecf2;
    --trading-order-tabs-active-bg: #ffffff;
    --trading-order-tabs-color: #888e92;
    --trading-order-tabs-active-color: #101115;
    --trading-order-tabs-hover-color: #101115;
    --trading-order-info-list-row-color: #343841;

    --toggle-bg: #8f969b;
    --toggle-bg-disabled: #d6e0e7;
    --toggle-before-bg: #ffffff;
    --toggle-on-bg: #000;
    --toggle-on-before-bg: #ffffff;

    --theme-switcher-toggle-bg: #A7AEBB;

    --checkbox-border: #000;
    --checkbox-border-hover: #000;
    --checkbox-border-checked: #000;
    --checkbox-bg-checked: #000;
    --checkbox-border-disabled: #a7aebb;
    --checkbox-icon-url: url("/images/checkbox-check_white.svg");

    --number-input-border: #343841;
    --number-input-color: #000;
    --number-input-disabled-border: #c2c4c6;
    --number-input-disabled-color: #c2c4c6;
    --number-input-focus-bg: #e5ecf2;

    --trades-table-btn-color: #343841;
    --trades-table-btn-border: #343841;
    --trades-table-btn-hover-bg: #101115;
    --trades-table-btn-hover-border: #101115;
    --trades-table-btn-hover-color: #fff;

    --trades-history-table-color: #343841;
    --trades-history-table-row-border-bg: #e1e4ed;
    --trades-history-header-table-color: #343841;
    --trades-history-table-bg: #fff;
    --trades-history-table-body-row-hover-bg: #e6ecf1;
    --trades-history-table-header-row-border-color: #343841;

    --trades-history-pagination-color: #343841;
    --trades-history-pagination-hover-border-color: #89909C;
    --trades-history-pagination-active-color: #ffffff;
    --trades-history-pagination-active-bg: #343841;
    --trades-history-pagination-dots-color: #343841;
    --trades-history-pagination-prev-icon-url: url("/images/pagination-prev-light.svg");
    --trades-history-pagination-prev-disabled-icon-url: url("/images/pagination-prev-disabled-light.svg");
    --trades-history-pagination-next-icon-url: url("/images/pagination-next-light.svg");
    --trades-history-pagination-next-disabled-icon-url: url("/images/pagination-next-disabled-light.svg");

    --trades-history-table-filter-period-list-item-bg: #e6ecf1;
    --trades-history-table-filter-period-list-item-bg-active: #000000;
    --trades-history-table-filter-period-list-item-bg-hover: #ffffff;
    --trades-history-table-filter-period-list-item-bg-light: #343841;

    --trades-history-table-filter-period-list-item-color: #89909c;
    --trades-history-table-filter-period-list-item-color-active: #ffffff;
    --trades-history-table-filter-period-list-item-color-hover: #343841;
    --trades-history-table-filter-date-picker-color-hover: #343841;
    --trades-history-table-filter-date-picker-color: #343841;

    --trades-history-table-datepicker-icon-url: url("/images/datepicker_calendar_grey.svg");
    --trades-history-table-datepicker-icon-url-hover: url("/images/datepicker_calendar_grey.svg");

    --trades-history-download-pdf-btn-color: #343841;
    --trades-history-download-pdf-btn-hover-color: #ffffff;
    --trades-history-download-pdf-btn-border: #343841;
    --trades-history-download-pdf-btn-bg: #ffffff;
    --trades-history-download-pdf-btn-hover-bg: #343841;
    --trades-history-download-pdf-btn-icon: url("/images/download-pdf_dark.svg");
    --trades-history-download-pdf-btn-hover-icon: url("/images/download-pdf.svg");
    --trades-history-mbl-download-pdf-btn-icon: url("/images/downloadBlack.svg");

    --date-input-border: #343841;
    --date-input-color: #343841;
    --date-input-url: url("/images/datepicker_calendar_grey.svg");
    --date-input-hovew-url: url("/images/datepicker_calendar_white.svg");
    --date-input-hover-border: #4e525c;
    --date-input-hover-color: #343841;
    --date-input-hover-bg: #e6ecf1;

    --order-error-title-color: #343841;
    --order-error-details-color: #343841;
    --order-error-modal-info-area-bg: #F4F6FB;
    --order-error-modal-info-area-desktop-bg: #E6ECF1;

    --order-close-modal-btn-confirm-bg: #000000;
    --order-close-modal-btn-confirm-bg-hover: #464a53;
    --order-close-modal-btn-confirm-color: #ffffff;
    --order-close-modal-btn-confirm-border: none;
    --order-close-modal-btn-confirm-bg-hover: #464A53;

    --order-close-modal-btn-cancel-bg: #ffffff;
    --order-close-modal-btn-cancel-bg-hover: #e8e9ee;
    --order-close-modal-btn-cancel-border-hover: 2px solid #89909C;
    --order-close-modal-btn-cancel-color: #000000;
    --order-close-modal-btn-cancel-border: 2px solid #000000;
    --order-close-modal-partial-checkbox-border: #000;
    --order-close-modal-partial-close-title-color: #343841;
    --order-close-modal-color: #343841;
    --order-delete-confirm-modal-color: #000000;
    --order-delete-confirm-modal-border: #000000;
    --order-delete-confirm-modal-hover: #E8E9EE;
    --order-delete-confirm-modal-hover-color: #000000;
    --order-delete-confirm-modal-hover-border: #89909C;
    --order-close-modal-pressed-btn-cancel-bg: #4C515E;
    --order-close-modal-pressed-btn-cancel-color: #ffffff;

    --order-modal-action-btn-color: #ffffff;
    --order-modal-action-btn-bg: #000000;
    --order-modal-action-btn-hover-bg: #464a53;
    --order-modal-action-pressed-btn-bg: #4C515E;
    --order-modal-action-pressed-btn-color: #ffffff;

    // trading order -- form
    --trading-order-header-close-color: #000000;
    --trading-order-top-info-asset-name-color: #101115;
    --trading-order-buy-sell-hover-color: #ffffff;
    --trading-order-buy-sell-disabled: #e0e5f0;
    --trading-order-modal-input-hover: #e6ecf1;
    --trading-order-modal-backdrop-bg: rgba(66, 69, 75, 0.9);
    --trading-order-submit-disabled-bg: #e4ecf1;
    --trading-order-submit-disabled-color: #abb0bd;

    // chart - nav
    --chart-nav-btn-bg: #e6ecf1;
    --chart-nav-btn-color: #343841;
    --chart-nav-btn-hover-color: #101115;
    --chart-nav-btn-active-bg: #000000;
    --chart-nav-btn-active-color: #ffffff;
    // chart - nav - open trade
    --chart-nav-open-trade-btn-bg: #ffffff;
    --chart-nav-open-trade-btn-border: 1px solid #343841;
    --chart-nav-open-trade-btn-color: #343841;
    --chart-nav-open-trade-btn-hover-bg: #343841;
    --chart-nav-open-trade-btn-hover-color: #ffffff;
    // chart - time
    --chart-time-color: #343841;

    // Information section
    --info-text-color: #343841;

    // Info List
    --infolist-heading-color: #343841;
    --infolist-striped-odd-bg: #e6ecf1;
    --infolist-striped-odd-mbl-bg: #F4F6FB;
    --infolist-glossary-bg: #e6ecf1;
    --infolist-glossary-label-color: #343841;
    --infolist-glossary-mbl-bg: #ffffff;

    --lot-size-input-empty-value-color: #C8D0D9;

    // Set lot size mobile
    --set-lot-size-mbl-default-bg: #ffffff;
    --set-lot-size-mbl-default-color: #000000;
    --set-lot-size-mbl-menu-close-icon-bg: #e0e5f0;
    --set-lot-size-mbl-menu-close-icon-url: url("/images/xBlack.svg");
    --set-lot-size-mbl-order-info-row-bg: #f4f6fb;

    // date range picker mobile
    --date-range-mbl-default-color: #000000;
    --date-range-mbl-disabled-color: #9098AD;
    --date-range-mbl-default-bg: #ffffff;
    --date-range-mbl-view-type-border: #e0e5f0;
    --date-range-mbl-view-date-bg: #f4f6fb;
    --date-range-mbl-view-date-border: #e0e5f0;
    --date-range-mbl-calendar-icon-url: url("/images/smallCalendarBlack.svg");
    --date-range-mbl-menu-close-icon-bg: #e0e5f0;
    --date-range-mbl-menu-close-icon-url: url("/images/xBlack.svg");
    --date-range-mbl-menu-type-item-border: #e0e5f0;
    --date-range-mbl-calendar-header-border: #e0e5f0;
    --date-range-mbl-calendar-day-active-bg: #000000;
    --date-range-mbl-calendar-day-active-color: #ffffff;
    --date-range-mbl-calendar-day-intermediate-bg: #f4f6fb;
    --date-range-mbl-calendar-day-intermediate-color: #000000;
    --date-range-mbl-calendar-arrow-icon-url: url("/images/arrowRight_lightGrey.svg");
    --date-range-mbl-calendar-arrow-active-icon-url: url("/images/arrowRight_black.svg");
    --date-range-mbl-calendar-arrow-up-icon-url: url("/images/arrow_up_black.svg");
    --date-range-mbl-calendar-arrow-up-icon-bg: #E0E5F0;
    --date-mbl-calendar-arrow-down-btn-url: url("/images/arrow_down_btn_light.svg");

    // System feedback mobile
    --system-feedback-mbl-default-color: #000000;
    --system-feedback-mbl-default-bg: #ffffff;
    --system-feedback-mbl-order-info-row-bg: #f4F6fb;
    --system-feedback-mbl-btn-color: #ffffff;
    --system-feedback-mbl-btn-bg: #000000;
    --system-feedback-mbl-pressed-btn-bg: #4C515E;
    --system-feedback-mbl-pressed-btn-color: #ffffff;
    --system-feedback-mbl-description-color: #4c515e;
    --system-feedback-mbl-market-closed-url: url("/images/marketClosedBlack.svg");
    --system-feedback-mbl-funds-url: url("/images/fundsBlack.svg");
    --system-feedback-mbl-title-bg: #000000;

    // Candlesticks tab
    --candlesticks-mbl-bg: #ffffff;
    --candlesticks-mbl-active-bg: #4c515e;
    --candlesticks-mbl-color: #000000;
    --candlesticks-mbl-active-color: #ffffff;
    --candlesticks-mbl-border: #e0e5f0;
    --candlesticks-mbl-line-icon-url: url("/images/chartType/Line.svg");
    --candlesticks-mbl-line-icon-active-url: url("/images/chartType/Line_white.svg");
    --candlesticks-mbl-area-icon-url: url("/images/chartType/Area.svg");
    --candlesticks-mbl-area-icon-active-url: url("/images/chartType/Area_white.svg");
    --candlesticks-mbl-candlesticks-icon-url: url("/images/chartType/Candlesticks.svg");
    --candlesticks-mbl-candlesticks-icon-active-url: url("/images/chartType/Candlesticks_white.svg");
    --candlesticks-mbl-hlc-icon-url: url("/images/chartType/Bars.svg");
    --candlesticks-mbl-hlc-icon-active-url: url("/images/chartType/Bars_white.svg");
    --candlesticks-mbl-dots-icon-url: url("/images/chartType/Dots.svg");
    --candlesticks-mbl-dots-icon-active-url: url("/images/chartType/Dots_white.svg");
    --candlesticks-mbl-heikinashi-icon-url: url("/images/chartType/Heikinashi.svg");
    --candlesticks-mbl-heikinashi-icon-active-url: url("/images/chartType/Heikinashi_white.svg");
    --candlesticks-mbl-hollow-candlesticks-icon-url: url("/images/chartType/HollowCandlesticks.svg");
    --candlesticks-mbl-hollow-candlesticks-icon-active-url: url("/images/chartType/HollowCandlesticks_white.svg");
    --candlesticks-mbl-ohlc-icon-url: url("/images/chartType/OHLC.svg");
    --candlesticks-mbl-ohlc-icon-active-url: url("/images/chartType/OHLC_white.svg");
    --candlesticks-mbl-histogram-icon-url: url("/images/chartType/Histogram.svg");
    --candlesticks-mbl-histogram-icon-active-url: url("/images/chartType/Histogram_white.svg");
    --candlesticks-mbl-columns-icon-url: url("/images/chartType/Columns.svg");
    --candlesticks-mbl-columns-icon-active-url: url("/images/chartType/Columns_white.svg");
    --candlesticks-mbl-line-and-dots-icon-url: url("/images/chartType/LineAndDots.svg");
    --candlesticks-mbl-line-and-dots-icon-active-url: url("/images/chartType/LineAndDots_white.svg");

    // sentiments tab
    --sentiments-mbl-bg: #f4f6fb;
    --sentiments-mbl-color: #1C1F25;
    --sentiments-mbl-footer-text-color: #4c515e;

    // time frames tab
    --time-frame-mbl-btn-selected-bg: #4C515E;
    --time-frame-mbl-btn-border: #E0E5F0;
    --time-frame-mbl-btn-selected-color: #ffffff;

    // toast-alert
    --toast-alert-bg: #4c515e;
    --toast-alert-color: #ffffff;
    --toast-alert-icon-favorite-added-url: url("/images/FavoriteAdded_white.svg");
    --toast-alert-icon-favorite-removed-url: url("/images/FavoriteRemoved_white.svg");

    --toast-alert-top-layer-bg: #4C515E;
    --toast-alert-center-layer-bg: #383B45;
    --toast-alert-bottom-layer-bg: #23252B;

    --clock-icon-url: url("/images/clock_light.svg");
    --clock-icon-hover-url: url("/images/clock_light.svg");
    --datepicker-icon-url: url("/images/datepicker_icon_black.svg");
    --datepicker-icon-hover-url: url("/images/datepicker_icon_black.svg");
    --account-info-trade-panel-item-bg: #e0e5f0;
    --account-info-trade-panel-item-dots-bg: #f4f6fb;
    --account-info-trade-close-icon-bg: transparent;
    --account-info-trade-panel-item-img-url: url("/images/accountIcon_gray.svg");
    --account-info-trade-panel-item-dots-img-url: url("/images/threeDots_black.svg");

    --calendar-drawer-date-container-color: #000000;
    --calendar-drawer-month-switcher-bg: #E0E5F0;
    --calendar-drawer-overlay-bg: #fff;
    --calendar-drawer-inner-wrp-bg: #fff;
    --calendar-drawer-inner-wrp-divider: #e0e5f0;
    --calendar-drawer-switcher-right-bg: url("/images/arrow_left_pale.svg");
    --calendar-drawer-switcher-right-bg-active: url("/images/arrow_right_black.svg");

    --calendar-drawer-switcher-left-bg: url("/images/arrow_left_pale.svg");
    --calendar-drawer-switcher-left-bg-active: url("/images/arrow_right_black.svg");

    --calendar-container-bg: #fff;
    --calendar-container-btn-color: #000;
    --calendar-container-tile-active-color: #fff;
    --calendar-container-tile-active-bg: #000;
    --calendar-container-day-color: #000000;

    --calendar-container-tile-now-color: #000;
    --calendar-container-tile-now-bg: #f4f6fb;

    --calendar-btn-set-color: #fff;
    --calendar-btn-set-bg: #000000;
    --calendar-btn-set-disabled-bg: #E0E5F0;
    --calendar-btn-set-disabled-color: #9098AD;

    --calendar-btn-cancel-bg: #fff;
    --calendar-btn-cancel-color: #000000;

    --calendar-btn-cancel-touched-bg: #4C515E;
    --calendar-btn-cancel-touched-color: #ffffff;

    --calendar-btn-set-touched-bg: #4C515E;
    --calendar-btn-set-touched-color: #FFFFFF;

    --calendar-btn-cancel-border: 2px solid #000000;
    --timepicker-swiper-item-selected: #000000;
    --timepicker-swiper-item-unselected: #E0E5F0;

    --pending-orders-drawerWrp-bg: #fff;
    --pending-orders-trade-panel-item-bg: #e0e5f0;

    --pending-orders-svg-icon-url: url("/images/alarmIcon_gray.svg");

    --pending-orders-trade-panel-item-dots-bg-img-url: url("/images/calendarIcon_black.svg");
    --pending-orders-trade-panel-item-dots-active-bg-img-url: url("/images/calendarIcon_black.svg");
    --pending-orders-trade-panel-item-dots-bg: #f4f6fb;

    --pending-orders-pricesetter-border: 2px solid #9098ad;
    --pending-orders-setter-bg: #000000;

    --pending-orders-minus-bg-img-url: url("/images/minus_white.svg");
    --pending-orders-plus-bg-img-url: url("/images/plus_white.svg");

    --pending-orders-trade-panel-item-expanded-bg: #ffffff;
    --pending-orders-trade-panel-item-expanded-box-shadow: 0px 1px 8px rgba(149, 157, 177, 0.283572);

    --trading-assets-section-mbl-border: 1px solid #e0e5f0;

    // date picker
    --date-picker-time-set-action-btn-color: #ffffff;
    --date-picker-time-set-action-btn-bg: #000000;
    --date-picker-time-set-action-btn-hover-bg: #464a53;
    --date-picker-time-cancel-action-btn-border: #000000;
    --date-picker-time-cancel-action-btn-color: #000000;
    --date-picker-time-cancel-action-btn-hover-bg: #343841;
    --date-picker-time-cancel-action-btn-hover-color: #ffffff;

    --profit-loss-border-color-mbl: #f4f6fb;
    --profit-loss-separator-color-mbl: #E0E5F0;

    // wallet
    --wallet-action-panel-poi-icon-bg: url("/images/poi_light.svg");
    --wallet-action-panel-por-icon-bg: url("/images/por_light.svg");
    --wallet-action-panel-pod-icon-bg: url("/images/pod_light.svg");
    --wallet-action-panel-por-tablet-icon-bg: url("/images/por_tablet_light.svg");
    --wallet-action-panel-poi-tablet-icon-bg: url("/images/poi_tablet_light.svg");
    --wallet-action-panel-pod-tablet-icon-bg: url("/images/pod_tablet_light.svg");

    --backdrop-bg: rgba(0, 0, 0, 0.5);

    --close-icon-bg: url("/images/close_cross_black.svg");

    // fallback screen
    --fallback-screen-icon: url("/images/fallback_light.svg");
    --fallback-screen-mobile-icon: url("/images/fallback_mobile_light.svg");
    --fallback-screen-tablet-icon: url("/images/fallback_tablet_light.svg");

    --fallback-screen-action-btn-bg: #000000;
    --fallback-screen-action-btn-color: #ffffff;
    --fallback-screen-title-color: #000000;
    --fallback-screen-subtitle-color: #343841;
}
