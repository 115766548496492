.iconContainer {
    width: 64px;
    height: 64px;
    margin: 0 auto;
    margin-bottom: 24px;
}

.successTopIcon {
    width: 44px;
    height: 44px;
}

.title {
    margin-bottom: 12px;
    color: var(--order-error-title-color) !important;
}
