html.theme-light {
    #chart-full-page-div-modal {
        background-color: #ffffff !important;
    }

    #chart-full-page-div-modal-bg {
        background-color: #ffffff !important;
    }

    prochart {
        .layout1.htmlchart {
            background-color: #ffffff;
        }

        .layout1 .chart-pane-main {
            border-top: 1px solid #BFBFBF;
        }

        .layout1 .adv-svgchart-inner {
            border: 1px solid #555;
        }

        .layout1 .chart-sprite.font-icon {
            background-image: none !important;
        }

        .layout1 button {
            background-color: transparent;
            color: #343841;
            background-repeat: no-repeat;
            border: 2px solid #89909c;
            border-radius: 2px;
        }

        .layout1 button:hover {
            background-color: #C8D0D9;
            color: #343841;
            border-color: transparent;
            border-radius: 2px;
            border: 2px solid transparent;
        }

        .layout1 button.button-selected {
            background-color: #e6ecf1;
            color: #343841;
            border-color: transparent;
            border-radius: 2px;
            border-bottom: 0;
            border: 2px solid transparent;
            border-bottom: 0;
        }

        .layout1 button.disabled:hover,
        .layout1 button.disabled {
            background-color: #eeeeee;
        }

        .layout1 button.font-icon {
            background-image: none !important;
        }

        .layout1 .chart-header .search-symbolics.font-icon,
        .layout1 .chart-header #search-symbolics.font-icon {
            background-image: none !important;
        }

        .layout1 .chart-header .search-symbolics-subpoint,
        .layout1 .chart-header #search-symbolics-subpoint {
            border-bottom: 10px solid rgba(0, 0, 0, 0);
            border-left: 7px solid #ffcd3b;
            border-top: 10px solid rgba(0, 0, 0, 0);
        }

        .layout1 .chart-header .symbol-title,
        .layout1 .chart-header #symbol {
            color: #fff;
        }

        .layout1 .chart-header .header-timeframe,
        .layout1 .chart-header #timeframe {
            color: #666666;
        }

        .layout1 .chart-header .header-lastupdate,
        .layout1 .chart-header #spanLastUpdate {
            color: #2d2d2d;
        }

        .layout1
            .chart-header
            .chart-header-search
            .chart-header-search-input
            .chart-header-search-input-text {
            color: #333333;
            border: #cccccc 1px solid;
            background-color: #f7f7f7;
        }

        .layout1
            .chart-header
            .chart-header-search
            .chart-header-search-input
            .chart-header-search-input-text:-webkit-input-placeholder,
        .layout1
            .chart-header
            .chart-header-search
            .chart-header-search-input
            .chart-header-search-input-text:-moz-placeholder,
        .layout1
            .chart-header
            .chart-header-search
            .chart-header-search-input
            .chart-header-search-input-text:-moz-placeholder,
        .layout1
            .chart-header
            .chart-header-search
            .chart-header-search-input
            .chart-header-search-input-text:-ms-input-placeholder {
            color: #333333;
        }

        .layout1 .chart-header .chart-header-search .chart-header-search-output {
            background-color: #ffffff;
            border: #333333 1px solid;
            box-shadow: 5px 5px 5px #888888;
        }

        .layout1
            .chart-header
            .chart-header-search
            .chart-header-search-output
            ul.search-output-list
            li {
            border-bottom: 1px dotted #dddddd;
        }

        .layout1
            .chart-header
            .chart-header-search
            .chart-header-search-output
            ul.search-output-list
            li
            a {
            color: #000000 !important;
        }

        .layout1
            .chart-header
            .chart-header-search
            .chart-header-search-output
            ul.search-output-list
            li
            a:hover
            span {
            color: #2d71ce;
        }

        .layout1
            .chart-header
            .chart-header-search
            .chart-header-search-output
            ul.search-output-list
            li
            button {
            background-color: #ffffff;
        }

        .layout1 .chart-header .chart-header-search .search-properties-tabs-labels {
            background-color: #ffcd3b;
        }

        .layout1
            .chart-header
            .chart-header-search
            .search-properties-tabs-labels
            a.search-properties-tabs-label {
            color: #000000;
        }

        .layout1
            .chart-header
            .chart-header-search
            .search-properties-tabs-labels
            a.search-properties-tabs-label.active {
            color: #fff;
            background-color: #000;
        }

        .layout1 .chart-header .chart-header-search .search-properties-filters {
            border-bottom: 1px solid #dddddd;
        }

        .layout1
            .chart-header
            .chart-header-search
            .search-properties-filters
            ul.search-properties-filters-list
            li.search-properties-filter
            span {
            color: #777777;
        }

        .layout1
            .chart-header
            .chart-header-search
            .search-properties-filters
            ul.search-properties-filters-list
            li.search-properties-filter
            > a {
            color: #000000;
        }

        .layout1
            .chart-header
            .chart-header-search
            .search-properties-filters
            ul.search-properties-filters-list
            li.search-properties-filter
            .filter-up-range {
            border-right: 7px solid rgba(0, 0, 0, 0);
            border-bottom: 7px solid #333333;
            border-left: 7px solid rgba(0, 0, 0, 0);
        }

        .layout1
            .chart-header
            .chart-header-search
            .search-properties-filters
            ul.search-properties-filters-list
            li.search-properties-filter
            .filter-down-point {
            border-right: 3px solid rgba(0, 0, 0, 0);
            border-top: 3px solid #000000;
            border-left: 3px solid rgba(0, 0, 0, 0);
        }

        .layout1
            .chart-header
            .chart-header-search
            .search-properties-filters
            ul.search-properties-filters-list
            li.search-properties-filter
            ul.search-properties-filter-values {
            background-color: #f0f0f0;
            border: 1px solid #333333;
            box-shadow: 5px 5px 5px #888888;
        }

        .layout1
            .chart-header
            .chart-header-search
            .search-properties-filters
            ul.search-properties-filters-list
            li.search-properties-filter
            ul.search-properties-filter-values
            li
            a {
            color: #fff;
        }

        .layout1
            .chart-header
            .chart-header-search
            .search-properties-filters
            ul.search-properties-filters-list
            li.search-properties-filter
            ul.search-properties-filter-values
            li:hover {
            background-color: #ffffff;
        }

        .layout1 .chart-menu {
            background-color: #ffcd3b;
        }

        .layout1 .chart-menu > ul > li {
            color: #333333;
        }

        .layout1 .chart-menu > ul > li:hover {
            background-color: #333333;
        }

        .layout1 .chart-menu > ul > li:hover > a {
            color: #cccccc;
        }

        .layout1 .chart-menu > ul > li > a.menu-selected {
            background-color: #333333;
            color: #cccccc;
        }

        .layout1 .chart-menu > ul > li > a:hover {
            background-color: #333333;
            color: #ffffff;
        }

        .layout1 .chart-menu > ul > li > ul {
            background-color: #f0f0f0;
            border: 1px solid #333333;
            box-shadow: 5px 5px 5px #888888;
        }

        .layout1 .chart-menu > ul > li > ul > li {
            color: #333333;
        }

        .layout1 .chart-menu > ul > li > ul > li:hover {
            background-color: #ffffff;
        }

        .layout1 .chart-menu > ul > li > .mn-item-sub-group,
        .layout1 .chart-menu > ul > li > .mn-item-group {
            background-color: #f0f0f0;
            border: 1px solid #333333;
            box-shadow: 5px 5px 5px #888888;
        }

        .layout1 .chart-menu > ul > li > .mn-item-sub-group > ul > li,
        .layout1 .chart-menu > ul > li > .mn-item-group > ul > li {
            color: #333333;
        }

        .layout1 .chart-menu > ul > li > .mn-item-sub-group > ul > li:hover,
        .layout1 .chart-menu > ul > li > .mn-item-group > ul > li:hover {
            background-color: #ffffff;
        }

        .layout1 .chart-menu .mn-item-sub-group {
            background-color: #f0f0f0;
            border: 1px solid #333333;
            box-shadow: 5px 5px 5px #888888;
        }

        .layout1 .chart-menu .mn-item-sub-group > ul > li {
            color: #333333;
        }

        .layout1 .chart-menu .mn-item-sub-group > ul > li:hover {
            background-color: #ffffff;
        }

        .layout1 .chart-menu a.mn-item-link:before.font-icon {
            background-image: none !important;
        }

        .layout1 .chart-menu .chart-menu-icons button {
            background-color: rgba(0, 0, 0, 0);
        }

        .layout1 .chart-menu .a-bottom .mn-array {
            border-color: transparent transparent #333333;
        }

        .layout1 .chart-menu .a-bottom .mn-array > div {
            border-color: transparent transparent #f7f7f7;
        }

        .layout1 .chart-menu .a-left .mn-array {
            border-color: transparent transparent transparent #333333;
        }

        .layout1 .chart-menu .a-left .mn-array > div {
            border-color: transparent transparent transparent #f7f7f7;
        }

        .layout1 .chart-menu .a-right .mn-array {
            border-color: transparent #333333 transparent transparent;
        }

        .layout1 .chart-menu .a-right .mn-array > div {
            border-color: transparent #f7f7f7 transparent transparent;
        }

        .layout1 .chart-menu .a-top .mn-array {
            border-color: #333333 transparent transparent;
        }

        .layout1 .chart-menu .a-top .mn-array > div {
            border-color: #f7f7f7 transparent transparent;
        }

        .layout1 ul.toolbar {
            background-color: #eeeeee;
        }

        .layout1 ul.toolbar li span.label {
            color: #aaaaaa;
        }

        .layout1 ul.toolbar-adv {
            background-color: #ffffff;
        }

        .layout1 ul.toolbar-adv > li span.toolbar-adv-label {
            color: #aaaaaa;
        }

        .layout1 ul.toolbar-adv > li span.toolbar-adv-space {
            background-color: #343841;
        }

        .layout1 ul.toolbar-adv .tlb-space span.toolbar-adv-space {
            background-color: #343841;
        }

        .layout1 ul.toolbar-adv .a-bottom > .tlb-array {
            border-color: transparent transparent #333333;
        }

        .layout1 ul.toolbar-adv .a-bottom > .tlb-array > div {
            border-color: transparent transparent #ffffff;
        }

        .layout1 ul.toolbar-adv .a-top .mn-array {
            border-color: #ffffff transparent transparent;
        }

        .layout1 ul.toolbar-adv .a-top .mn-array > div {
            border-color: #ffffff transparent transparent;
        }

        .layout1 ul.toolbar-adv .a-left > .tlb-array {
            border-color: transparent transparent transparent #333333;
        }

        .layout1 ul.toolbar-adv .a-left > .tlb-array > div {
            border-color: transparent transparent transparent #ffffff;
        }

        .layout1 ul.toolbar-adv .a-right > .tlb-array {
            border-color: transparent #333333 transparent transparent;
        }

        .layout1 ul.toolbar-adv .a-right > .tlb-array > div {
            border-color: transparent #ffffff transparent transparent;
        }

        .layout1 ul.toolbar-adv .a-top > .tlb-array {
            border-color: #ffffff transparent transparent;
        }

        .layout1 ul.toolbar-adv .a-top > .tlb-array > div {
            border-color: #ffffff transparent transparent;
        }

        .layout1 ul.toolbar-adv .sub-point {
            box-sizing: border-box;
            width: 6px;
            height: 6px;
            border-top: solid 3px transparent;
            border-left: solid 3px transparent;
            border-right: solid 3px #343841;
            border-bottom: solid 3px #343841;
            border-bottom-right-radius: 2px;
        }

        .layout1 ul.toolbar-adv .tlb-more .tlb-more-items {
            border: 1px solid #333333;
            background-color: #ffffff;
            box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.18);
        }

        .layout1 ul.toolbar-adv .tlb-item-group .tlb-button-groups {
            border: 1px solid #89909C;
            background-color: #FFFFFF;
            box-shadow: 2px 2px 12px rgba(0,0,0,0.18);
        }

        .layout1 ul.toolbox li span#signal-list-alert {
            background-color: red;
            color: #ffffff;
        }

        .layout1 ul.toolbox li span.label {
            color: #aaaaaa;
        }

        .layout1 ul.toolbox li.sub .sub-right-range {
            border-bottom: 7px solid rgba(0, 0, 0, 0);
            border-right: 7px solid #dddddd;
            border-top: 7px solid rgba(0, 0, 0, 0);
        }

        .layout1 ul.toolbox li.sub .sub-point {
            box-sizing: border-box;
            width: 6px;
            height: 6px;
            border-top: solid 3px transparent;
            border-left: solid 3px transparent;
            border-right: solid 3px #ffffff;
            border-bottom: solid 3px #ffffff;
            border-bottom-right-radius: 2px;
        }

        .layout1 ul.toolbox li.sub:hover > ul {
            background-color: #f0f0f0;
            border: 1px solid #dddddd;
            border-radius: 2px 2px 2px 2px;
        }

        .layout1 ul.toolbox li.sub > ul > li:hover > button {
            background-color: #ffffff;
        }

        .layout1 ul.toolbox li.sub > ul > li > button {
            background-color: rgba(0, 0, 0, 0);
            font-size: var(--desk-font-size-1);
            padding-left: 30px;
            text-align: left;
            min-width: 130px;
        }

        .layout1 .zoom-height-space {
            display: block;
            height: 60px;
            width: 60px;
        }

        .layout1 .adv-svgchart-zoom {
            position: relative;
        }

        .layout1 .adv-svgchart-zoom.zoom-on-bottom-page {
            position: fixed;
            bottom: 2px;
            background-color: #ffffff;
            padding: 10px;
        }

        .layout1 .adv-svgchart-zoom #svgZoomChart .AdvStudy .AdvBarGroup line.ADVZoomLine {
            stroke: #6f5300;
        }

        .layout1 .adv-svgchart-zoom #svgZoomChart .AdvStudy .AdvBarGroup polygon.ADVZoomAreaStroke {
            fill: #ffecb0;
            stroke: #ffecb0;
            stroke-width: 1px;
        }

        .layout1 .adv-svgchart-zoom #svgZoomChart rect.ADVZoomOutOfRange {
            fill: #ffffff;
            fill-opacity: 0.6;
            stroke: #ffffff;
            stroke-opacity: 0.6;
            stroke-width: 2px;
        }

        .layout1 .adv-svgchart-zoom .zoom-chart-container .zoom-rect-left,
        .layout1 .adv-svgchart-zoom .zoom-chart-container .zoom-rect-right {
            position: absolute;
            height: 30px;
            top: 0px;
            border: 0px solid #000000;
            width: 100px;
            opacity: 0.7;
            background-color: #ffffff;
        }

        .layout1 .adv-svgchart-zoom .slider-zoom .ui-rangeSlider .ui-rangeSlider-container {
            border-radius: 0px;
            border-left: 1px solid #c2c5d6;
            border-right: 1px solid #c2c5d6;
        }

        .layout1 .adv-svgchart-zoom .slider-zoom .ui-rangeSlider .ui-rangeSlider-arrow {
            background: none repeat scroll 0 0 #6f5300;
            border-bottom: 1px solid #666666;
            border-top: 1px solid #333333;
            cursor: pointer;
        }

        .layout1 .adv-svgchart-zoom .slider-zoom .ui-rangeSlider .ui-rangeSlider-leftArrow {
            border-left: 1px solid #6f5300;
            border-radius: 0px 0 0 0px;
        }

        .layout1 .adv-svgchart-zoom .slider-zoom .ui-rangeSlider .ui-rangeSlider-rightArrow {
            border-radius: 0 3px 3px 0;
            border-right: 1px solid #6f5300;
        }

        .layout1 .adv-svgchart-zoom .slider-zoom .ui-rangeSlider .ui-rangeSlider-label {
            background: none repeat scroll 0 0 #6f5300;
            box-shadow: 0 1px 0 #c2c5d6;
            color: #ffffff;
        }

        .layout1 .zoom-date-range .date-end,
        .layout1 .zoom-date-range .date-start {
            color: #6f5300;
        }

        .layout1 .zoom-buttons:hover {
            button {
                background: #FFFFFF;
                border: 2px solid #C8D0D9;
                opacity: 1;

                &:last-of-type {
                    border-right: 2px solid #C8D0D9!important;
                }
            }
        }

        .layout1 .zoom-buttons button {
            background: transparent;
            border: none;
            opacity: 0.15;
        }

        .layout1 .zoom-buttons-intent {
            background-color: transparent;
            opacity: 1;
            filter: alpha(opacity=60);
        }

        .layout1 .chart-price-latupdate {
            color: #2d2d2d;
        }

        .layout1 .chart-pane-signal .chart-pane-alert.flash {
            background-color: rgba(255, 205, 59, 0.1);
        }

        .layout1 {
            .chart-controlpanel .controlpanel-buttons a span,
            .chart-pane-watermark .legend-buttons a span,
            .chart-pane-legend-signal .legend-buttons a span,
            .chart-pane-legend-study .legend-buttons a span,
            .chart-pane-legend-plugin .legend-buttons a span,
            .chart-pane-legend-price .legend-buttons a span {
                background-image: url(/images/chart/theme-light/img-sprite.png);
                opacity: .1;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .layout1 .chart-controlpanel .controlpanel-buttons a span.font-icon {
            background-image: none !important;
        }

        .layout1 .chart-pane-watermark,
        .layout1 .chart-pane-legend-signal,
        .layout1 .chart-pane-legend-study,
        .layout1 .chart-pane-legend-plugin,
        .layout1 .chart-pane-legend-price {
            background-color: transparent;
        }

        .layout1 .chart-pane-watermark .legend-title,
        .layout1 .chart-pane-legend-signal .legend-title,
        .layout1 .chart-pane-legend-study .legend-title,
        .layout1 .chart-pane-legend-plugin .legend-title,
        .layout1 .chart-pane-legend-price .legend-title {
            color: #343841;
        }

        .layout1 .legend-visible-icon,
        .layout1 .legend-properties-icon {
            position: relative;
            top: 1px;
        }

        .layout1 .legend-properties-icon {
            margin-right: 5px;
        }

        .layout1 .chart-pane-legend-price .legend-value {
            width: 55px;
            margin-right: 5px;
        }

        .layout1 .chart-pane-watermark .legend-description.legend-compare-description,
        .layout1 .chart-pane-legend-signal .legend-description.legend-compare-description,
        .layout1 .chart-pane-legend-study .legend-description.legend-compare-description,
        .layout1 .chart-pane-legend-plugin .legend-description.legend-compare-description,
        .layout1 .chart-pane-legend-price .legend-description.legend-compare-description {
            color: #343841;
        }

        .layout1 .chart-pane-splitter.draggable-split-h .chart-pane-splitter-inner {
            background: rgba(255, 153, 255, 0.3);
        }

        .layout1 .chartpane {
            border-bottom: none;
        }

        .layout1 .chart-date-axis {
            border-top: 1px solid #bfbfbf;
            background-color: #ffffff;
        }

        .layout1 .chart-price-axis {
            border-left: 1px solid #bfbfbf;
        }

        .layout1 button.legend-collapsebutton {
            border: 1px solid #dddddd;
        }

        .layout1 button.legend-collapsebutton:hover {
            background-color: #dddddd;
        }

        .layout1 .alert-container .alert-tooltip {
            border: 1px solid #000;
            background-color: white;
            box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        }

        .layout1 .alert-container .alert-point {
            color: #fff;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
        }

        /**
         * added styles
         */

        .toolbar-adv .icon-button span.pointer1-icon {
            background-image: url(/images/chart/theme-light/pointer-light.svg) !important;
            width: 12px;
            height: 18px;
        }

        .toolbar-adv .icon-button span.delete-icon {
            background-image: url(/images/chart/theme-light/delete-light.svg) !important;
            width: 15px;
            height: 15px;
        }

        .toolbar-adv .icon-button span.delete-shapes {
            background-image: url(/images/chart/theme-light/delete-all-light.svg) !important;
        }

        .toolbar-adv .icon-button span.delete-indicators {
            background-image: url(/images/chart/theme-light/delete-all-studies-light.svg) !important;
        }

        .toolbar-adv .icon-button span.many-windows-icon {
            background-image: url(/images/chart/theme-light/duplicate-shape-light.svg) !important;
        }

        .toolbar-adv .icon-button span.ray-line {
            background-image: url(/images/chart/theme-light/ray-light.svg) !important;
        }

        .toolbar-adv .icon-button span.vertical-line {
            background-image: url(/images/chart/theme-light/ray2-light.svg) !important;
        }

        .toolbar-adv .icon-button span.horizontal-line {
            background-image: url(/images/chart/theme-light/horizontal-line-light.svg) !important;
        }

        .toolbar-adv .icon-button span.extended-line {
            background-image: url(/images/chart/theme-light/extended-line-light.svg) !important;
        }

        .toolbar-adv .icon-button span.arrow {
            background-image: url(/images/chart/theme-light/arrow-light.svg) !important;
        }

        .toolbar-adv .icon-button span.trendline {
            background-image: url(/images/chart/theme-light/trend-line-light.svg) !important;
        }

        .toolbar-adv .icon-button span.measure {
            background-image: url(/images/chart/theme-light/measure-light.svg) !important;
        }

        .toolbar-adv .icon-button span.parallel-lines {
            background-image: url(/images/chart/theme-light/parallel-lines-light.svg) !important;
        }

        .toolbar-adv .icon-button span.rectangle {
            background-image: url(/images/chart/theme-light/rectangle-light.svg) !important;
        }

        .toolbar-adv .icon-button span.draw-arc {
            background-image: url(/images/chart/theme-light/arc-light.svg) !important;
        }

        .toolbar-adv .icon-button span.ellipce {
            background-image: url(/images/chart/theme-light/ellipse-light.svg) !important;
        }

        .toolbar-adv .icon-button span.circle {
            background-image: url(/images/chart/theme-light/circle-light.svg) !important;
        }

        .toolbar-adv .icon-button span.triangle {
            background-image: url(/images/chart/theme-light/triangle-light.svg) !important;
        }

        .toolbar-adv .icon-button span.polygon {
            background-image: url(/images/chart/theme-light/polygon-light.svg) !important;
        }

        .toolbar-adv .icon-button span.text-tool {
            background-image: url(/images/chart/theme-light/abc-light.svg) !important;
        }

        .toolbar-adv .icon-button span.fib-retracement {
            background-image: url(/images/chart/theme-light/fibo-light.svg) !important;
        }

        .toolbar-adv .icon-button span.adrews_pitchfork {
            background-image: url(/images/chart/theme-light/andrews-pitchfork-light.svg) !important;
        }

        .toolbar-adv .icon-button span.fib-timezone {
            background-image: url(/images/chart/theme-light/fibo-timezones-light.svg) !important;
        }

        .toolbar-adv .icon-button span.fib-fan {
            background-image: url(/images/chart/theme-light/fibo-fan-light.svg) !important;
        }

        .toolbar-adv .icon-button span.fib-arc {
            background-image: url(/images/chart/theme-light/fibo-arc-light.svg) !important;
        }

        .toolbar-adv .icon-button span.fib-gannfan {
            background-image: url(/images/chart/theme-light/gann-fan-light.svg) !important;
        }

        .toolbar-adv .icon-button span.fib-extension {
            background-image: url(/images/chart/theme-light/fibo-extension-light.svg) !important;
        }

        .toolbar-adv .icon-button span.fib-time-extentsion {
            background-image: url(/images/chart/theme-light/time-extension-light.svg) !important;
        }

        .toolbar-adv .icon-button span.fib-gann-square {
            background-image: url(/images/chart/theme-light/gann-square-light.svg) !important;
        }

        .toolbar-adv .icon-button span.regression-channel {
            background-image: url(/images/chart/theme-light/linear-regression-light.svg) !important;
        }

        .toolbar-adv .icon-button span.cycle-lines {
            background-image: url(/images/chart/theme-light/cycle-lines-light.svg) !important;
        }

        .toolbar-adv .icon-button span.candlesticks {
            background-image: url(/images/chart/theme-light/candlesticks-light.svg) !important;
        }

        .toolbar-adv .icon-button span.heikinashi {
            background-image: url(/images/chart/theme-light/heikinashi-light.svg) !important;
        }

        .toolbar-adv .icon-button span.hlc {
            background-image: url(/images/chart/theme-light/hlc-light.svg) !important;
        }

        .toolbar-adv .icon-button span.line {
            background-image: url(/images/chart/theme-light/line-light.svg) !important;
        }

        .toolbar-adv .icon-button span.area {
            background-image: url(/images/chart/theme-light/area-light.svg) !important;
        }

        .toolbar-adv .icon-button:hover span.area {
            background-image: url(/images/chart/theme-light/area-hover-light.svg) !important;
        }

        .toolbar-adv .icon-button span.dots {
            background-image: url(/images/chart/theme-light/dots-light.svg) !important;
        }

        .toolbar-adv .icon-button:hover span.dots {
            background-image: url(/images/chart/theme-light/dots-hover-light.svg) !important;
        }

        .toolbar-adv .icon-button span.camera {
            background-image: url(/images/chart/theme-light/screenshot-light.svg) !important;
        }

        .toolbar-adv .icon-button span.zoom-full {
            background-image: url(/images/chart/theme-light/fullscreen-light.svg) !important;
        }

        .toolbar-adv .icon-button span.indicatorform {
            background-image: url(/images/chart/theme-light/indicators-light.svg) !important;
        }

        .toolbar-adv .icon-button span.icon-more {
            background-image: url(/images/chart/theme-light/more-light.svg) !important;
            width: 10px;
            bottom: 0;
            top: auto;
        }

        .icon-button span.zoom-left {
            background-image: url(/images/chart/theme-light/zoom/left-light.svg) !important;
        }

        .icon-button span.zoom-right {
            background-image: url(/images/chart/theme-light/zoom/right-light.svg) !important;
        }

        .icon-button span.zoom-plus {
            background-image: url(/images/chart/theme-light/zoom/in-light.svg) !important;
        }

        .icon-button span.zoom-minus {
            background-image: url(/images/chart/theme-light/zoom/out-light.svg) !important;
        }

        .icon-button span.zoom-all {
            background-image: url(/images/chart/theme-light/zoom/all-light.svg) !important;
        }

        .icon-button span.zoom-default {
            background-image: url(/images/chart/theme-light/zoom/reset-light.svg) !important;
        }
    }

    /**
     * Dialogs
     */
    .chart-ui-dialog.ui-dialog {
        padding: 0;
        box-shadow: 0 0 25px rgba(0,0,0,0.5);
    }

    .chart-ui-dialog.ui-widget-content,
    .chart-ui-dialog .ui-widget-content {
        background-color: var(--bg);
        color: var(--color);
        border: none;
    }

    .chart-ui-dialog .ui-widget-header {
        background-color: #a7aebb;
        color: #343841;
        border-bottom: none;
    }

    .chart-ui-dialog .adv-dialog-content .dialog-indicators div#dialog-main-page .dialog-main-page-title {
        background-color: #a7aebb;
        color: #343841;
    }

    .chart-ui-dialog .adv-dialog-content .dialog-indicators div#dialog-main-page .dialog-main-page-title>ul>li.title_level.active>a {
        color: #343841;
    }

    .chart-ui-dialog .adv-dialog-content .dialog-indicators {
        border-left: 1px solid #808288;
    }

    .chart-ui-dialog .browser-item-selected {
        color: #2d71ce;
        cursor: default!important;

        &:hover {
            color: #2d71ce;
        }
    }

    .dialog-indicators-browser li:hover {
        color: #2d71ce;
    }

    .chart-ui-dialog .adv-dialog-content .dialog-indicators div#dialog-main-page .dialog_level .main-properties ul.favorite-indicators-list li.li-indicator:hover a span,
    .chart-ui-dialog .adv-dialog-content .dialog-indicators div#dialog-main-page .dialog_level .main-properties ul.indicators-list li.li-indicator:hover a span {
        color: #f25555;
    }

    .ui-dialog-buttonpane.ui-widget-content {
        border-top: 1px solid #a5aaad;
        margin-top: 0;
    }

    .chart-ui-dialog .adv-dialog-content a {
        color: #343841;

        &:hover {
            color: #2d71ce;
        }
    }

    .chart-ui-dialog .adv-dialog-content .properties-tabs .properties-tabs-label.active {
        color: #000;
        cursor: default;
    }

    .chart-ui-dialog .ui-dialog-titlebar {
        padding: 8px 10px!important;
    }

    .chart-ui-dialog .ui-state-default,
    .chart-ui-dialog .ui-widget-content .ui-state-default,
    .chart-ui-dialog .ui-widget-header .ui-state-default {
        border: none;
        background: #a7aebb;
        color: #343841;
    }

    .chart-ui-dialog .adv-dialog-content .dialog-indicators div#dialog-main-page .dialog_level .main-properties ul.favorite-indicators-list li.li-indicator a,
    .chart-ui-dialog .adv-dialog-content .dialog-indicators div#dialog-main-page .dialog_level .main-properties ul.indicators-list li.li-indicator a {
        color: #343841!important;
    }

    /**
     * Dialogs and other global elements
     */
    .indicators-dialog {
        .indicators-list,
        .favorite-indicators-list {
            .favorite {
                opacity: 1;

                &.star-button {
                    background-position: 4px 3px;

                    &.stardisabled-icon {
                        background-image: url(/images/chart/theme-light/indicators-modal/favorites.svg)!important;
                    }

                    &.startenabled-icon {
                        background-image: url(/images/chart/theme-light/indicators-modal/favorites-active.svg)!important;
                    }
                }

                &.add-favorite {
                    background-position: 1px 0;

                    background-image: url(/images/chart/theme-light/indicators-modal/check.svg)!important;

                    &:hover {
                        background-image: url(/images/chart/theme-light/indicators-modal/check-hover.svg)!important;
                    }
                }

                &.property-favorite.a-property-favorite {
                    background-position: 4px 3px;

                    background-image: url(/images/chart/theme-light/indicators-modal/settings.svg)!important;

                    &:hover {
                        background-image: url(/images/chart/theme-light/indicators-modal/settings-hover.svg)!important;
                    }
                }
            }
        }
    }
}
