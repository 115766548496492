.closeTradeTitleWrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #101115;

    .closeTradeTitle {
        font-weight: 600;
        font-size: var(--desk-font-size-1-8);
        line-height: 130%;
        margin-left: 24px;
        padding-right: 50px;
    }
}

.orderDetails {
    padding-top: 12px;
    font-size: var(--desk-font-size-1-3);
    line-height: 16px;
}

.profitInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    padding: 16px;
    gap: 4px;
    background: var(--order-error-modal-info-area-desktop-bg);

    border-radius: 4px;
}

.profitInfoLabel {
    color: var(--order-close-modal-color);
    font-weight: 700;
    font-size: var(--desk-font-size-1-8);
    line-height: 22px;
    text-align: center;
}

.profitInfoValue {
    font-weight: 700;
    font-size: var(--desk-font-size-3-2);
    line-height: 39px;
    text-align: center;
    color: var(--order-close-modal-color);

    &.profitInfoValueProfit {
        color: var(--profit);
    }

    &.profitInfoValueLoss {
        color: var(--loss);
    }
}

.partialClose {
    margin-top: 16px;
}

.partialCloseCheckboxRow,
.partialCloseInputRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.partialCloseCheckbox {
    border: 1px solid var(--order-close-modal-partial-checkbox-border);
}

.partialCloseTitle {
    color: var(--order-close-modal-partial-close-title-color) !important;
    cursor: pointer !important;
}

.partialCloseCheckboxRow {
    margin-bottom: 16px;

    label {
        font-weight: 600;
        font-size: var(--desk-font-size-1-3);
        color: var(--order-close-modal-partial-close-title-color);
        cursor: pointer;
        display: flex;
        align-items: center;
    }
}

.partialCloseInputRow {
    position: relative;

    .alertMessage {
        position: absolute;
        top: 15px;
        display: flex;
        justify-content: left;
        width: 100%;
        margin-top: 19px;
        font-weight: 600;
        font-size: 13px;
        line-height: 130%;
        color: #f25555;
    }

    .numberLabel {
        font-weight: 600;
        font-size: var(--desk-font-size-1-4);
        line-height: 1.7;
        color: #89909c;
        margin-right: 12px;
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
    }

    .numberLabelActive {
        color: var(--order-close-modal-partial-close-title-color);
    }

    .volumeInput {
        font-size: var(--desk-font-size-1-3);
    }
}

.footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 30px;
    gap: 8px;

    .confirm,
    .cancel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 42px;
        border-radius: 8px;
        border: none;
        cursor: pointer;
        z-index: 100;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;

        text-align: center;
    }

    .confirm {
        margin-right: 8px;
        background: var(--order-close-modal-btn-confirm-bg);
        color: var(--order-close-modal-btn-confirm-color);
        border: var(--order-close-modal-btn-confirm-border);

        &:hover {
            background: var(--order-close-modal-btn-confirm-bg-hover);
        }
    }

    .cancel {
        background: var(--order-close-modal-btn-cancel-bg);
        color: var(--order-close-modal-btn-cancel-color);
        border: var(--order-close-modal-btn-cancel-border);

        &:hover {
            background: var(--order-close-modal-btn-cancel-bg-hover);
        }
    }
}

.closeIcon {
    right: 13px !important;
    top: 13px !important;
    background-image: url("/images/close_cross_white.svg") !important;

    &:hover {
        background-image: url("/images/close_cross_pale.svg") !important;
    }
}

.infoListContainer {
    margin-bottom: 16px;
}