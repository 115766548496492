.container {
    margin-bottom: 32px;
}

.heading {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    align-items: center;

    img {
        display: block;
        margin-right: 0.375rem;
        width: var(--desk-font-size-1-6);
        height: var(--desk-font-size-1-6);
    }

    h3 {
        font-size: var(--desk-font-size-1-6);
        line-height: 1.1;
        font-weight: 700;
        color: var(--infolist-heading-color);
    }
}

.content,
.content_mobile {
    &.glossary {
        background: var(--infolist-glossary-bg);
        padding: 12px 0;
        border-radius: 4px;
    }
}

.maxHeight {
    height: calc((var(--vh, 1vh) * 100) - 205px);

    @media (max-width: 1367px) {
        height: calc((var(--vh, 1vh) * 100) - var(--footer-height));
        max-height: 600px;
        max-height: 276px;
    }
}

.content_mobile {
    &.glossary {
        background: var(--infolist-glossary-mbl-bg);
        padding: 0;
    }
}

.row,
.row_mobile {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    margin: 16px 0;

    &.decreased {
        margin: 10px 0 !important;
    }

    .glossary & {
        display: block;
        padding: 0 12px;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .dense & {
        margin: 0;
    }

    .striped & {
        &:nth-child(2n) {
            background: var(--infolist-striped-odd-bg);
        }
    }

    .striped_mobile & {
        &:nth-child(2n) {
            background: var(--infolist-striped-odd-mbl-bg);
        }
    }

    .striped_informaiton & {
        &:last-child {
            background: var(--bg);
        }
    }
}

.row_mobile,
.row_mobileGlossary {
    margin: 0;
}

.row_mobileGlossary {
    padding: 8px;
}

.label,
.value {
    padding: 6px 11px;

    font-size: var(--desk-font-size-1-3);

    .glossary_dark & {
        padding: 0;
    }

    .glossary &,
    .glossaryMobile &,
    &:empty {
        padding: 0;
    }
}

.label_mobile,
.value_mobile {
    padding: 8px;
    text-align: left !important;
}

.value {
    text-align: right;

    &.information_value_desktop {
        min-width: 100px;
    }
}

.value_number {
    max-width: 110px;
}

.label {
    font-weight: 700;

    .description & {
        display: none;
    }

    .glossary & {
        color: var(--infolist-glossary-label-color);
        margin-right: 0.5rem;
    }
}

.description_important {
    position: relative;
    bottom: 10px;
    color: var(--trades-info-section-color);
    background: var(--bg);
    font-weight: 600;
    text-align: left;
}

.description {
    background: var(--infolist-striped-odd-bg);
    border-radius: 4px;
    width: 100%;
    text-align: left;
}

.description_mobile {
    background: var(--infolist-striped-odd-mbl-bg);
}
