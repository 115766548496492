@font-face {
    font-family: "IcoFont";
    font-weight: normal;
    font-style: "Regular";
    font-display: swap;
    src: url("/static/fonts/iconfont/icofont.woff2") format("woff2"),
        url("/static/fonts/iconfont/icofont.woff") format("woff");
}

[class^="icofont-"],
[class*=" icofont-"] {
    font-family: "IcoFont" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

.icofont-check:before {
    content: "\eed8";
}

.icofont-card:before {
    content: "\eed0";
}

.icofont-dice-multiple:before {
    content: "\ef02";
}

.icofont-favourite:before {
    content: "\ef25";
}

.icofont-eye:before {
    content: "\ef24";
}

.icofont-eye-blocked:before {
    content: "\ef22";
}

.icofont-file-document:before {
    content: "\eb0e";
}

.icofont-navigation-menu:before {
    content: "\efa2";
}

.icofont-user-alt-3:before {
    content: "\ecfd";
}

.icofont-unlock:before {
    content: "\f01a";
}

.icofont-ui-v-card:before {
    content: "\ec8f";
}

.icofont-id-card:before {
    content: "\e970";
}

.icofont-credit-card:before {
    content: "\eef4";
}

.icofont-papers:before {
    content: "\efb6";
}

.icofont-file-spreadsheet:before {
    content: "\eb27";
}

.icofont-stopwatch:before {
    content: "\edcd";
}

.icofont-stop:before {
    content: "\ecb1";
}

.icofont-pay:before {
    content: "\efb7";
}

.icofont-close:before {
    content: "\eee4";
}

.icofont-facebook:before {
    content: "\ed37";
}

.icofont-instagram:before {
    content: "\ed46";
}

.icofont-history:before {
    content: "\ef46";
}

.icofont-calendar:before {
    content: "\eecd";
}

.icofont-info-circle:before {
    content: "\ef4e";
}

.icofont-info:before {
    content: "\ef50";
}

.icofont-home:before {
    content: "\ef47";
}

.icofont-search-2:before {
    content: "\ed12";
}

.icofont-search-1:before {
    content: "\ed11";
}

.icofont-arrow-right:before {
    content: "\ea5d";
}

.icofont-arrow-left:before {
    content: "\ea5c";
}

.icofont-play-alt-2:before {
    content: "\eca7";
}

.icofont-ui-play:before {
    content: "\ec74";
}

.icofont-heart:before {
    content: "\ef45";
}

.icofont-search-document:before {
    content: "\ed13";
}

.icofont-camera:before {
    content: "\eecf";
}

.icofont-trophy:before {
    content: "\edd7";
}

.icofont-upload-alt:before {
    content: "\f01c";
}

.icofont-user-alt-1:before {
    content: "\ecfb";
}

.icofont-user-alt-2:before {
    content: "\ecfc";
}

.icofont-user-alt-5:before {
    content: "\ecff";
}

.icofont-search-user:before {
    content: "\ed1a";
}

.icofont-user:before {
    content: "\ed05";
}

.icofont-caret-left:before {
    content: "\ea68";
}

.icofont-caret-right:before {
    content: "\ea69";
}

.icofont-ban:before {
    content: "\eead";
}

.icofont-exclamation-circle:before {
    content: "\ef19";
}

.icofont-exclamation-square:before {
    content: "\ef1a";
}

.icofont-notification:before {
    content: "\efac";
}

.icofont-contact-add:before {
    content: "\e96d";
}

.icofont-smart-phone:before {
    content: "\efec";
}

.icofont-youtube-play:before {
    content: "\ecbb";
}

.icofont-logout:before {
    content: "\ef7c";
}

.icofont-bin:before {
    content: "\eebb";
}

.icofont-login:before {
    content: "\ef7b";
}

.icofont-minus-circle:before {
    content: "\ef98";
}

.icofont-flag-alt-2:before {
    content: "\ef2e";
}

.icofont-flag:before {
    content: "\ef2f";
}

.icofont-bill:before {
    content: "\eeba";
}

.icofont-coins:before {
    content: "\e96b";
}

.icofont-bill-alt:before {
    content: "\e964";
}

.icofont-spinner-alt-3:before {
    content: "\eff6";
}

.icofont-expand:before {
    content: "\ef1e";
}

.icofont-exclamation-triangle:before {
    content: "\ef1b";
}

.icofont-gift:before {
    content: "\ef3c";
}

.icofont-infinite:before {
    content: "\ef4d";
}

.icofont-tasks:before {
    content: "\f007";
}

.icofont-video:before {
    content: "\ecb5";
}

.icofont-bell-alt:before {
    content: "\eacf";
}

.icofont-ui-game:before {
    content: "\ec5c";
}

.icofont-compass:before {
    content: "\eeed";
}

.icofont-chart-histogram:before {
    content: "\e97f";
}

.icofont-ui-calendar:before {
    content: "\ec45";
}

.icofont-users-alt-5:before {
    content: "\ed0a";
}

.icofont-clock-time:before {
    content: "\eedc";
}

.icofont-ui-love:before {
    content: "\ec65";
}

.icofont-undo:before {
    content: "\ee0b";
}

.icofont-rounded-down:before {
    content: "\ea99";
}

.icofont-rounded-up:before {
    content: "\eaa1";
}

.icofont-play:before {
    content: "\ecaa";
}

.icofont-close-line:before {
    content: "\eee1";
}

.icofont-power:before {
    content: "\efc4";
}

.icofont-thin-down:before {
    content: "\eac8";
}

.icofont-simple-down:before {
    content: "\eab2";
}

.icofont-xs {
    font-size: 0.5em;
}

.icofont-sm {
    font-size: 0.75em;
}

.icofont-md {
    font-size: 1.25em;
}

.icofont-lg {
    font-size: 1.5em;
}

.icofont-1x {
    font-size: 1em;
}

.icofont-2x {
    font-size: 2em;
}

.icofont-3x {
    font-size: 3em;
}

.icofont-4x {
    font-size: 4em;
}

.icofont-5x {
    font-size: 5em;
}

.icofont-6x {
    font-size: 6em;
}

.icofont-7x {
    font-size: 7em;
}

.icofont-8x {
    font-size: 8em;
}

.icofont-9x {
    font-size: 9em;
}

.icofont-10x {
    font-size: 10em;
}

.icofont-fw {
    text-align: center;
    width: 1.25em;
}

.icofont-ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
}

.icofont-ul > li {
    position: relative;
    line-height: 2em;
}

.icofont-ul > li .icofont {
    display: inline-block;
    vertical-align: middle;
}

.icofont-border {
    border: solid 0.08em #f1f1f1;
    border-radius: 0.1em;
    padding: 0.2em 0.25em 0.15em;
}

.icofont-pull-left {
    float: left;
}

.icofont-pull-right {
    float: right;
}

.icofont.icofont-pull-left {
    margin-right: 0.3em;
}

.icofont.icofont-pull-right {
    margin-left: 0.3em;
}

.icofont-spin {
    animation: icofont-spin 2s infinite linear;
    display: inline-block;
}

.icofont-pulse {
    animation: icofont-spin 1s infinite steps(8);
    display: inline-block;
}

@-webkit-keyframes icofont-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes icofont-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.icofont-rotate-90 {
    transform: rotate(90deg);
}

.icofont-rotate-180 {
    transform: rotate(180deg);
}

.icofont-rotate-270 {
    transform: rotate(270deg);
}

.icofont-flip-horizontal {
    transform: scale(-1, 1);
}

.icofont-flip-vertical {
    transform: scale(1, -1);
}

.icofont-flip-horizontal.icofont-flip-vertical {
    transform: scale(-1, -1);
}

:root .icofont-rotate-90,
:root .icofont-rotate-180,
:root .icofont-rotate-270,
:root .icofont-flip-horizontal,
:root .icofont-flip-vertical {
    filter: none;
    display: inline-block;
}

.icofont-inverse {
    color: #fff;
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}
