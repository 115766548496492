.container {
    position: relative;
    user-select: none;

    &.fullWidth {
        width: 100%;
    }
}

.input {
    height: 24px;
    width: 100%;
    font-size: var(--desk-font-size-1);
    font-weight: 700;
    line-height: 130%;
    padding: 5px 40px 4px 8px;
    background: transparent;
    outline: none;
    border: 1px solid var(--number-input-border);
    border-radius: 2px;
    color: var(--number-input-color);

    &:focus {
        background: var(--number-input-focus-bg);
    }

    .disabled & {
        color: var(--number-input-disabled-color);
        border-color: var(--number-input-disabled-border);
    }

    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.actions {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 4px;
    display: flex;
    justify-content: space-between;
    width: 36px;
    background: transparent;
    z-index: 3;
}

.increase,
.decrease,
.increase_light,
.decrease_light {
    position: relative;
    //font-family: monospace;
    font-family: var(--main-font-family);
    font-size: var(--desk-font-size-1-1);
    font-weight: 600;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border: 1.5px solid var(--number-input-color);
    border-radius: 16px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .disabled & {
        cursor: default;
        pointer-events: none;
        border-color: var(--number-input-disabled-color);
    }

    &:before {
        content: "";
        width: 8px;
        height: 2px;
        background: var(--number-input-color);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        bottom: 0;
        margin: auto;

        .disabled & {
            background: var(--number-input-disabled-color);
        }
    }
}

.increase {
    &:after {
        content: "";
        width: 8px;
        height: 2px;
        background: var(--number-input-color);
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotateZ(90deg);
        top: 0;
        bottom: 0;
        margin: auto;

        .disabled & {
            background: var(--number-input-disabled-color);
        }
    }
}

.increase_light,
.decrease_light {
    border: 1.5px solid var(--number-input-active-bg);

    .disabled & {
        border-color: var(--number-input-disabled-bg);
    }

    &:before {
        content: "";
        width: 8px;
        height: 2px;
        background: var(--number-input-active-bg);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        bottom: 0;
        margin: auto;

        .disabled & {
            background: var(--number-input-disabled-bg);
        }
    }
}

.increase_light {
    &:after {
        content: "";
        width: 8px;
        height: 2px;
        background: var(--number-input-active-bg);
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotateZ(90deg);
        top: 0;
        bottom: 0;
        margin: auto;

        .disabled & {
            background: var(--number-input-disabled-bg);
        }
    }
}
