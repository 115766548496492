.action_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 42px;
    border-radius: 8px;
    font-size: var(--desk-font-size-1-2);
    line-height: 130%;
    z-index: 100;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    cursor: pointer;
}

.small {
    width: 101px;
    height: 26px;
}

.medium {
    width: 101px;
    height: 26px;
}

.large {
    width: 101px;
    height: 26px;
}

.white {
    background: var(--order-close-modal-btn-confirm-bg);
    color: var(--order-close-modal-btn-confirm-color);
    border: var(--order-close-modal-btn-confirm-border);

    &:hover {
        background: var(--order-close-modal-btn-confirm-bg-hover);
        color: var(--date-picker-time-cancel-action-btn-hover-color);
    }

    &.disabled {
        opacity: 0.2;
        cursor: default;

        &:hover {
            background: var(--order-close-modal-btn-confirm-bg);
            color: var(--order-close-modal-btn-confirm-color);
        }
    }
}

.black {
    background: var(--order-close-modal-btn-cancel-bg);
    color: var(--order-close-modal-btn-cancel-color);
    border: var(--order-close-modal-btn-cancel-border);

    &:hover {
        background: var(--order-close-modal-btn-cancel-bg-hover);
        border: var(--order-close-modal-btn-cancel-border-hover);
    }

    &.disabled {
        opacity: 0.2;
        cursor: default;

        &:hover {
            background: var(--order-close-modal-btn-cancel-bg);
            color: var(--order-close-modal-btn-cancel-color);
        }
    }
}